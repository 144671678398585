<template>
  <b-container class="px-0 bg-info rounded-lg p-2">
    <b-row>
      <b-col cols="1" class="border-1 ml-0 mr-0 " ><b-icon @click="toggleCollapse" :icon="getCaretIcon()" variant="primary" /></b-col>
      <b-col cols="11" class="ml-0" ><a @click="toggleCollapse" href="#">{{ headerText }}</a></b-col>
    </b-row>
    <b-collapse id="collapse-2" :visible="collapsVisible" class="mt-2"  >
      <AutoComplete :items="nevoIngredients" textField="naam" 
            valueField="id"  selectSize="4" @itemSelected="selectNevoIngredient"
            @onMouseUp="collapsVisible = false" @onEnterPressed="collapsVisible = false" />
    </b-collapse>
  </b-container>
</template>
<script>
import AutoComplete from "./Autocomplete.vue"
export default {
  name:"select-nevo-ingredient",
  props: ["nevoIngredients", "ingredient"],
  components: { AutoComplete},
  data(){
    return {
      collapsVisible: false,
      headerText: "Koppel Nutriënten"
    }
  },
  watch: {
    ingredient(value) {
      console.log("watch.ingredient: ", value)
      if(value){
        let nevoIngr = this.getNevoIngredient(value.ingredient.nevoId)
        console.log(nevoIngr)
        if(value && nevoIngr) {
          this.headerText = this.getHeaderText(nevoIngr, value)
        }
      }
    }
  },
  methods: {
    getCaretIcon() {
      return this.collapsVisible ? 'caret-down-fill' : 'caret-right-fill'
    },
    toggleCollapse() {
      this.collapsVisible = !this.collapsVisible
    },
    getNevoIngredient(nevoId) {
      for (let i = 0; i < this.nevoIngredients.length; i++) {
        const nevoIngr = this.nevoIngredients[i];
        if(nevoIngr.id == nevoId) {
          return nevoIngr
        }
      }
      return false
    },
    selectNevoIngredient(nevoIngrID){
      let nevoIngr = this.getNevoIngredient(nevoIngrID)
      this.$nextTick(() => this.headerText = this.getHeaderText(nevoIngr, this.ingredient))
      this.$emit("selectNevoIngredient", nevoIngr)
    },
    getHeaderText(nevoIngr, ingredient) {
      console.log("getHeaderText nevoIngr, ingredient: ", nevoIngr, ingredient)
      return "Nevo ingr: " + (!( nevoIngr.synomiem === undefined || nevoIngr.synomiem.indexOf("NULL") == 0 ) ? 
        nevoIngr.synomiem : nevoIngr.naam) + this.getKcalFromIngredient(ingredient)
    },
    getKcalFromIngredient(ingredient) {
      if(!ingredient) return "";
      return ingredient.kCal_pp ? ", " + ingredient.kCal_pp + " kCal/p" : ""
    },
    formatter(value) {
      return value < 0 ? 0 : value
    },
    calcNutrienten() {

    }
  },
  mounted() {
    console.log("mounted: ", this.ingredient)
    if(this.ingredient){
      let nevoIngr = this.getNevoIngredient(this.ingredient.ingredient.nevoId)
      console.log(nevoIngr)
      if(this.ingredient && nevoIngr) {
        this.headerText = this.getHeaderText(nevoIngr, this.ingredient)
      }
    }
  }
}
</script>

