<template>
   <b-modal id="modalEditIngredient" modal-class="modal-fullscreen" title="Pas ingredient aan" hide-backdrop
      size="md"  
      @show="initModal"
      @ok="handleIngredientModalOk">
      <b-form ref="ingredientForm" @submit.stop.prevent="modalEditIngredientSubmit()" >
         <b-form-group label="naam:" :invalid-feedback="naamInvalidInfo" :state="naamState">
          <b-form-input ref="inputNaam" class="col-6 px-1 px-sm-2" required :state="naamState"
            v-model="selectedIngredient.naam" />
        </b-form-group>
        <b-form-group label="meervoud:">
          <b-form-input class="col-6 px-1 px-sm-2" 
            v-model="selectedIngredient.meervoud" />
        </b-form-group>
        <b-form-group label="categorie:">
          <b-form-input class="col-6 px-1 px-sm-2" 
            v-model="selectedIngredient.categorie" />
        </b-form-group> 
      </b-form>
   </b-modal>
</template>
<script>
import db from "../db.js"

export default {
   name:"modal-edit-ingredient",
   props: ["userID", "ingredients" , "selectedIngredient"],
   data: function() {
      return {
         recipes: [],
         unsubscribe: null,
         oldName: null,
         naamState: null,
         naamInvalidInfo: "Deze naam kan niet toegevoegd worden",
         emptyIngredient: {id:null ,naam: "", meervoud: "", categorie: ""}
      };
   },
   methods: {
      initModal: function() {
         this.oldName = this.selectedIngredient.naam
         
         //this.selectedIngredient = Object.assign({}, this.selectedIngredient)
         this.focusInput()
      },
      focusInput: function() {  
         setTimeout(() => {
            this.$refs.inputNaam.select()
         },500)

      },
      handleIngredientModalOk: function(bvModalEvent) {
         bvModalEvent.preventDefault();
         this.modalEditIngredientSubmit();
      },
      handleCancel: function() {
         this.selectedIngredient = this.emptyIngredient
      },
      /**
       * 1) check of het veld naam van ingredient is ingevuld
       * 2) check of ingredient niet al bestaat
       * 3) zet categorie op "diversen" als hij niet ingevoerd is
       * 4) voeg het ingredient aan de DB toe onder local ingredients
       * 5) dispatch 'ingredientCreated' event
       * 6) sluit het venster 
       */
      modalEditIngredientSubmit: function() {
         this.naamState = true
         this.$refs.ingredientForm.checkValidity()
         this.selectedIngredient.naam = this.selectedIngredient.naam.trim()
         if(this.selectedIngredient.naam == "") {
            this.naamState = false;
            this.naamInvalidInfo = "Geef een naam op"
         } else {
            for (let i = 0; i < this.ingredients.length; i++) {
               const ingr = this.ingredients[i];
               if(ingr.text == this.selectedIngredient.naam) {
                  this.naamInvalidInfo = "Ingredient bestaat al"
                  this.naamState = false;
                  break;
               }
            }
         }
         if(!this.naamState ) {
            return
         }
         if(this.selectedIngredient.categorie == "") {
            this.selectedIngredient.categorie = "diversen"
         }
         const ingrRef = db.collection("users").doc(this.userID).collection("ingredients").doc(this.selectedIngredient.id)
         ingrRef.update(this.selectedIngredient).then(()=>{
               //find exact new object in ingredients to make matching possible in dropdown of ModalRecipeIngredient
               for (let i = 0; i < this.ingredients.length; i++) {
                  const ingr = this.ingredients[i];
                  if(ingr.value.naam == this.selectedIngredient.naam) {
                     this.selectedIngredient = ingr.value
                     break;
                  }
               }
               //no need to add to ingredients because the onSnapshot in App.js will add it to the clients ingredients array
               this.$emit("ingredientUpdated", this.selectedIngredient)
               //this.selectedIngredient = {id:null ,naam: "", meervoud: "", categorie: ""}
               this.$nextTick(()=> {
                  this.$bvModal.hide('modalEditIngredient')
               })

         })
         .catch(error => {
            console.log(error)
         })

      }
   },
}
</script>
