<template>
  <b-container class="mt-md-5 mb-3 px-0 px-md-3" >
    <b-row v-if="recipes.length > 0" class="justify-content-center">
      <b-col class="col-12 col-xl-9 px-0 px-md-3 mt-5">
        <b-card :title="getTitle(activeShoppingListName)" class="card bg-light ml-1 border-0 px-0 ">
          <b-card-body class="py-2 px-0 ">
            <b-container class="px-0">
              <b-row class="m-0">
                <b-col class="mr-3 px-0">
                  <b-input-group size="md">
                    <b-form-input 
                      type="text"
                      placeholder="Zoek recepten"
                      v-model="searchQuery"
                      ref="searchInput"
                      />
                  </b-input-group>
                </b-col>
                <b-col cols="auto" class="ml-auto px-0">
                  <b-button @click="createRecipe"
                    title="Recept aanmaken"
                    variant="primary"
                    class="ml-1"
                    :pressed="false">
                    <b-icon icon="plus" style="color: #FFFFFF;"></b-icon></b-button>
                  <b-button v-b-toggle.collapseFilter
                    size="md" 
                    :variant="filterCollapsed? 'primary' : 'default'"
                    @click="filterCollapsed ? selectedFilterTags=[] : false"
                    class="ml-1"
                    title="Filter recepten"
                    :pressed="false">
                    <b-icon :icon="filterCollapsed ? 'funnel-fill' : 'funnel'" :variant="filterCollapsed? 'white' : 'default'" style="color: #000000;" /></b-button>
                  <b-button @click="$emit('openModalInviteUser')"
                    class="ml-1"
                    variant="white"
                    title="Boodschappenlijstje delen"
                    :pressed="false">
                    <b-icon
                      icon="box-arrow-up"
                      style="color: #000000;"></b-icon></b-button>
                  <b-button size="md"  :variant="editable ? 'primary' :'white'"
                    v-b-toggle.collapseEditableVisible
                    class="ml-1"
                    title="Receptgegevens aanpassen" 
                    @click="editable = !editable, draggableList = false, !editable ? selectNothing() : false " >
                    <b-icon  :icon="editable? 'pencil-fill': 'pencil'" :variant="editable? 'white' : 'default'" style="color: #000000;"/></b-button >
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-collapse v-model="filterVisible" id="collapseFilter"  
                    @show="filterCollapsed=true" @hide="filterCollapsed=false">
                      <b-form-checkbox-group v-model="selectedFilterTags" :options="tags" class="mt-0" />
                  </b-collapse>
                </b-col>
              </b-row>

              <b-collapse v-model="editableCollapseVisible" id="collapseEditableVisible" >
                <b-row class="border-0">
                  <b-col class="col-9 mt-2 px-0 ml-2 mb-2">
                    <b-button @click="toggleSelected" 
                      variant="white" >
                      <b-icon :icon="allSelected ? 'check-square' : 'square'"  style="color: #000000;"/></b-button>
                    <b-button 
                      size="md" 
                      variant="white"
                      :disabled="!(editable && selectedItemCount>0)"
                      @click="deleteRecipes"
                      class=" ml-2 mt-0"
                      title="Verwijder recepten">
                      <b-icon 
                        icon="trash" style="color: #000000;" /></b-button>
                    <b-button 
                      v-if="roles && roles.includes('admin')"
                      :disabled="!(editable && selectedItemCount>0)"
                      v-b-toggle.collapseTag
                      size="md" 
                      :variant="tagCollapsed? 'primary' : 'default'"
                      class="ml-2 mt-0"
                      title="Label recepten">
                      <b-icon :icon="tagCollapsed ? 'tag-fill' : 'tag'" :variant="tagCollapsed? 'white' : 'default'" style="color: #000000;" /></b-button>
                    <b-button @click="showModalSelectRecipes"
                      title="Recept zoeken in kookboek"
                      variant="white"
                      class="ml-1"
                      :pressed="false">
                      <b-icon icon="book" style="color: #000000;"></b-icon></b-button>
                  </b-col>
                </b-row>
              </b-collapse>
              <b-row class="border-0">
                <b-col class="mt-0 ml-3 mr-3">
                  <b-collapse v-model="tagSelected"
                    @show="tagCollapsed=true" @hide="tagCollapsed=false"
                    v-if="roles && roles.includes('admin')" 
                    id="collapseTag"  >
                      <b-form-checkbox-group v-model="selectedLabelTags" :options="tags" class="mt-0" />
                      <b-button size="sm" :disabled="selectedLabelTags.length==0" @click="addTags" class="m-1">Tag toevoegen</b-button>
                      <b-button size="sm" :disabled="selectedLabelTags.length==0" @click="removeTags" class="m-1">Tag verwijderen</b-button>
                  </b-collapse>
                </b-col>
              </b-row>
            </b-container>

            <list-recipes class="mt-2" :filteredRecipes="filteredRecipes" :editable="editable" @countSelected="countSelected" />

          </b-card-body>
        </b-card>
      </b-col>
      <b-col class="col-12 col-xl-3 px-0 mt-0 mt-md-5">
        <CardSelectedRecipes 
          title="Menu"
          toTitle="Ga naar boodschappen"
          toLink="/shopping-list"
          deleteRecipeActive="true"
          :selectedRecipes="shoppingListRecipes" 
          @removeRecipe="removeRecipeFromShoppingList" />
      </b-col>
    </b-row>
    <b-row v-if="recipes.length == 0" class="justify-content-center m-5">
      <b-col class="mt-5">
        <p>Je hebt geen recepten. Neem recepten over uit het <a @click="showModalSelectRecipes" href="#">kookboek.</a></p>
      </b-col>
    </b-row>
    <modal-select-recipe 
      :tags="tags" 
      @addRecipes="addRecipes"/>
  </b-container>
</template>
<script>
import db from "../db.js";
import CardSelectedRecipes from "../components/CardSelectedRecipes"
import ModalSelectRecipe from "../components/ModalSelectRecipe"
import ListRecipes from "../components/ListRecipes"
// import firebase from "firebase"
export default {
  name: "recipe-list",
  data: function() {
    return {
      editable: false,
      shoppingList: [],
      recipes: [],
      filteredRecipes: [],
      unsubscribe: null,
      shoppingListRecipes: [],
      searchQuery:"",
      filterVisible: false,
      tagSelected: false,
      editableCollapseVisible: false,
      selectedFilterTags: [],
      selectedLabelTags: [],
      selectedItemCount: 0,
      tagCollapsed: false,
      filterCollapsed: false,
    };
  },
  props: ["user", "tags", "userID", "activeUserID", "activeShoppingListName", "roles"],
  components: {CardSelectedRecipes, ModalSelectRecipe, ListRecipes},
  watch: {
    tagSelected: function( selected ) {
      if( selected ) this.filterVisible = false;
      if(!selected ) this.selectedLabelTags = []
    },
    filterVisible: function( newValue ) {
      if( newValue ) this.tagSelected = false;
    },
    selectedItemCount: function (newValue) {
      if(newValue == 0) this.tagSelected = false;
    },
    searchQuery: function() {
      this.filterRecipes()
    },
    selectedFilterTags: function(newTags) {
      this.filterRecipes(newTags)
    },
    activeUserID: function(newID, oldID) {
      if(newID != null && newID != oldID) {
        this.loadData()
      }
    }
  },
  computed: {
    allSelected: function() {
      let selectCount = 0
      for (let i = 0; i < this.filteredRecipes.length; i++) {
        const rcp = this.filteredRecipes[i];
        if(rcp.selected) {
          selectCount++
        }
      }
      return selectCount == this.filteredRecipes.length
    }
  },
  methods: {
      /**
       * 1) Loops through this ingredients
       * 2) Multiplies aantal with the eaterPersonRatio
       * 3) Loops through the shoppingList to match ingredient and unit.
       * 4) If found adds up aantal of units, if not found adds ingredient to the shoppingList
       * 
       * param {*} recipe 
       * for recipe[ingredients] => i
       *   i.aantal = r.eaterPersonRatio * i.aantal
       *   for [this.shoppingList] => sli
       *     if(i.ingredient == sli.ingredient && i.eenheid == sli.eenheid)
       *       sli.aantal += i.aantal
       *       found = true
       *   if(!found)
       *     this.shoppingList.push(clone(i))
       */
      addRecipeToShoppingList: function(recipe) {
        const rcp = Object.assign({},recipe);
        rcp.recipeInstanceID = Date.now().toString(36);
        this.shoppingListRecipes.push(rcp)
        if (!recipe.eaterPersonRatio) {
          recipe.eaterPersonRatio = 1;
        }
        for (let j = 0; j < recipe.ingredients.length; j++) {
          const rcpItem = Object.assign({},recipe.ingredients[j]);
          rcpItem.aantal *= recipe.eaterPersonRatio;
          var found = false;
          for (let i = 0; i < this.shoppingList.length; i++) {
            const shpLstItem = this.shoppingList[i];
            if ( rcpItem.ingredient.id == shpLstItem.ingredient.id &&
              rcpItem.eenheid.id == shpLstItem.eenheid.id ) {
              found = true;
              shpLstItem.aantal += rcpItem.aantal;
              shpLstItem.selected = false;
              shpLstItem.recipeIDs.push({recipeInstanceID: rcp.recipeInstanceID, aantal: rcpItem.aantal})
            }
          }
          if (!found) { 
            rcpItem.recipeIDs = [{recipeInstanceID: rcp.recipeInstanceID, aantal: rcpItem.aantal}]
            this.shoppingList.push( rcpItem);
          }
        }
        const shoppingListRef = db.collection('users').doc(this.activeUserID).collection('shoppingLists').doc("active")
        shoppingListRef.update({items: this.shoppingList, recipes: this.shoppingListRecipes}).then(()=> {
          const toastObj = {
              title: `Recept toegevoegd`,
              toaster: 'b-toaster-bottom-left',
              variant: 'warning',
              solid: true,
              appendToast: false
            }
          this.$emit("showToast",["'" + recipe.naam + "' toegevoegd aan boodschappenlijstje",toastObj] )
        })
      },
      removeRecipeFromShoppingList: function(recipe) {
        for (let i = 0; i < this.shoppingListRecipes.length; i++) {
          const rcp = this.shoppingListRecipes[i];
          if(recipe.recipeInstanceID == rcp.recipeInstanceID) {
            this.shoppingListRecipes.splice(i, 1)
            break;
          }
        }
        if (!recipe.eaterPersonRatio) {
          recipe.eaterPersonRatio = 1;
        }
        var keep = []
        for (let i = 0; i < this.shoppingList.length; i++) {
          const shpLstItem = this.shoppingList[i];
          if(shpLstItem.recipeIDs) {
            for (let j = shpLstItem.recipeIDs.length-1; j >= 0; j--) {
              const rcpShpLstID = shpLstItem.recipeIDs[j];
              if(rcpShpLstID.recipeInstanceID == recipe.recipeInstanceID) {
                shpLstItem.aantal -= rcpShpLstID.aantal;
                shpLstItem.recipeIDs.splice(j, 1)
              } 
            }
          }
          
          if((shpLstItem.recipeIDs && shpLstItem.recipeIDs.length > 0)|| !shpLstItem.recipeIDs) { //if it still has recipes in the list or it is a custom SL item
            keep.push(shpLstItem)
          }
        }
        this.shoppingList = keep
        const shoppingListRef = db.collection('users').doc(this.activeUserID).collection('shoppingLists').doc("active")
        shoppingListRef.update({items: this.shoppingList, recipes: this.shoppingListRecipes})
      },
    
    addRecipes: function(newRecipes) {
      let toBeAddedRecipes = []
      let doubleRecipes = []
      for (let i = 0; i < newRecipes.length; i++) {
        const recipe = newRecipes[i];
        let found = false
        for (let j = 0; j < this.recipes.length; j++) {
          const thisRecipe = this.recipes[j];
          if(recipe.naam == thisRecipe.naam) {
            found = true
            doubleRecipes.push(recipe.naam)
            break
          }
        }
        if(!found) {
          toBeAddedRecipes.push(recipe.naam)
          db.collection('users').doc(this.activeUserID).collection('recipes').add(recipe).then( doc => {
            console.log(doc.id)
          })
        }
      }
      if(doubleRecipes.length > 0) {
        const toastObj = {
          title: `Dubbele recepten`,
          toaster: 'b-toaster-bottom-left',
          variant: 'danger',
          noAutoHide: false,
          solid: true,
          appendToast: false
        }
        this.$emit("showToast",[doubleRecipes.length + " Dubbel(e) recept(en) zijn niet toegevoegd omdat ze al in de lijst voorkomen. Verander de naam van een recept in onderstaande lijst als je hem toch wilt importeren.",toastObj] )
      }
      
      if(toBeAddedRecipes.length > 0) {
        const toastObj = {
          title: `Recepten toegevoegd`,
          toaster: 'b-toaster-bottom-left',
          variant: 'success',
          noAutoHide: false,
          solid: true,
          appendToast: false
        }
        this.$emit("showToast",[toBeAddedRecipes.length + " Recept(en) zijn toegevoegd aan de lijst.",toastObj] )
      }
      
    },
    filterRecipes: function(newTags = []) {
      this.filteredRecipes = this.recipes
      if(this.selectedFilterTags.length == 0 && this.searchQuery == "") {
        return
      }else if( this.searchQuery.length > 0) {
        this.filteredRecipes = this.recipes.filter(recipe => {
          return recipe.naam.toLowerCase().indexOf(this.searchQuery.toLowerCase()) != -1
        })
        if(this.selectedFilterTags.length == 0) {
          return;
        }
      }
      if(!newTags || newTags.length == 0) return;
      this.filteredRecipes = this.filteredRecipes.filter(recipe => {
        if (!recipe.tags) return false;
        for (let i = 0; i < recipe.tags.length; i++) {
          const tag = recipe.tags[i];
          for (let j = 0; j < newTags.length; j++) {
            const newTag = newTags[j];
            if(tag.id == newTag.id) {
              return true;
            }
          }
        }
        return false
      })
    },
    toggleSelected: function() {
      if(this.allSelected) {
        this.selectNothing()
      } else {
        this.selectAll()
      }
    },
    selectAll: function() {
      for (let i = 0; i < this.filteredRecipes.length; i++) {
        const rcp = this.filteredRecipes[i];
        rcp.selected = true;
      }
      this.selectedItemCount = this.filteredRecipes.length
    },
    selectNothing: function() {
      for (let i = 0; i < this.filteredRecipes.length; i++) {
        const rcp = this.filteredRecipes[i];
        rcp.selected = false;
      }
      this.selectedItemCount = 0
    },
    countSelected: function(event) {
      console.log(event)
      this.selectedItemCount = 0
      for (let i = 0; i < this.filteredRecipes.length; i++) {
        const rcp = this.filteredRecipes[i];
        if(rcp.selected) {
          this.selectedItemCount++
        }
      }
    },
    addTags: function() {
      for (let i = 0; i < this.filteredRecipes.length; i++) {
        const rcp = this.filteredRecipes[i];
        if(rcp.selected) {
          for (let j = 0; j < this.selectedLabelTags.length; j++) {
            const tag = this.selectedLabelTags[j];
            if(rcp.tags) {
              var found = false
              for (let h = 0; h < rcp.tags.length; h++) {
                const rcpTag = rcp.tags[h];
                if(rcpTag.id == tag.id) {
                  found = true
                  break;
                }
              }
              if(!found) {
                rcp.tags.push(tag)
              }
            }else {
              rcp.tags = [tag]
            }
          }
        }
      }
      this.saveSelectedRecipesTags()
    },
    removeTags: function() {
      for (let i = 0; i < this.filteredRecipes.length; i++) {
        const rcp = this.filteredRecipes[i];
        if(rcp.selected) {
          for (let j = 0; j < this.selectedLabelTags.length; j++) {
            const tag = this.selectedLabelTags[j];
            if(rcp.tags) {
              for (let h = 0; h < rcp.tags.length; h++) {
                const rcpTag = rcp.tags[h];
                if(rcpTag.id == tag.id) {
                  rcp.tags.splice(h, 1)
                  break;
                }
              }
            }else {
              rcp.tags = [tag]
            }
          }
        }
      }
      this.saveSelectedRecipesTags()
    },
    saveSelectedRecipesTags: function() {
      for (let i = 0; i < this.filteredRecipes.length; i++) {
        const rcp = this.filteredRecipes[i];
        if(rcp.selected) {
          db.collection("users").doc(this.activeUserID).collection("recipes").doc(rcp.id).update({tags: rcp.tags})
        }
      }
    },
    deleteRecipes: function(){
      let toBeDeletedRecipes = []
      for (let i = 0; i < this.filteredRecipes.length; i++) {
        const rcp = this.filteredRecipes[i];
        if(rcp.selected) {
          toBeDeletedRecipes.push(rcp.naam)
          db.collection("users").doc(this.activeUserID).collection("recipes").doc(rcp.id).delete().then(() => {
            console.log("Recept '" + rcp.naam + "' verwijderd")
          })
        }
      }
      const toastObj = {
        title: `Recept verwijderd`,
        toaster: 'b-toaster-bottom-left',
        variant: 'warning',
        solid: true,
        appendToast: false
      }
      this.$emit("showToast",["Recept '" + toBeDeletedRecipes + "' zijn verwijderd", toastObj] )
    },
    showModalSelectRecipes: function() {
      this.$bvModal.show("modalSelectRecipe")
    },
    /**
     * 1) navigate to detail page of recipe
     */
    navigateTo: function(item) {
      this.$router.push("/recipe-detail/" + item.id);
    },
    /**
     * 1) create a new recipe object
     * 2) add recipe to the users recipes
     * 3) navigate to recipe detail page
     * 
     * var recipe = {props}
     * DB.user.recipes.add(recipe).then(docRef => {
     *  navigateToPage(recipe-detail/ + docRef)
     * })
     */
    createRecipe: function() {
      var recipe = {
        aantal_personen: 4,
        naam: "* Nieuw recept *",
        beschrijving: "",
      };
      db.collection("users")
        .doc(this.activeUserID)
        .collection("recipes")
        .add(recipe)
        .then((docRef) => {
          console.log("Recept aangemaakt");
          this.$router.push("/recipe-detail/" + docRef.id);
          //save id on recipe
          recipe.id = docRef.id
          docRef.update(recipe).then( () => {
            console.log("Recept id toegevoegd")
          })
        })
        .catch((error) => {
          console.error("Error creating recipe: ", error);
        });
    },
    /**
     * 
     * 1) Give every shoppingListItem an index number
     * 2) Store the shoppingList
     */
    saveShoppingList: function() {
      const self = this;
      //create an id on each shoppinglist item
      for (let i = 0; i < this.shoppingList.length; i++) {
        const item = this.shoppingList[i];
        item.id = i;
      }
      
      let ref = db.collection("users").doc(this.activeUserID).collection("shoppingLists");
      ref.doc("active")
        .update({ items: self.shoppingList , recipes: self.shoppingListRecipes})
        .catch((error) => {
          console.error("Error saveShoppingList(): ", error);
        });
        
    },
    /**
     * get's the title of the view
     */
    getTitle: function(name) {
      return "Recepten " + name
    },
    /**
     * Helper function to show number of persons for a recipy in the list
     */
    getAantalPersonen: function(item) {
      var aantal =
        item.aantal_personen *
        (item.eaterPersonRatio ? item.eaterPersonRatio : 1);
      return aantal + " personen";
    },
    /**
     * 1) returns number of eater by multiplying the number of persons with the eater person ratio
     */
    getNumberOfEaters: function(item) {
      return item.aantal_personen * (item.eaterPersonRatio ? item.eaterPersonRatio : 1);
    },
    /**
     * 1) recalculate eater person ratio
     * 2) update recipe
     */
    calcEaterChange: function(recipe, number) {
      recipe.eaterPersonRatio = number / recipe.aantal_personen
      this.submitRecipe(recipe)
    },
    /**
     * 
     */
    submitRecipe: function(recipe) {
      db.collection('users').doc(this.activeUserID)
        .collection('recipes').doc(recipe.id)
        .update(recipe)
    },
    loadData: function() {
      if(this.activeUserID) {
        const activeUserRef = db.collection("users").doc(this.activeUserID)
        this.unsubscribe = activeUserRef.collection("recipes").onSnapshot((snapshot) => {
          var snapData = [];
          snapshot.forEach((doc) => {
            var recipe = doc.data();
            //selected is locally used and should not be saved, it should be implemented from start to make bindings work
            recipe.selected = false;
            recipe.id = doc.id;
            snapData.push(recipe);
          });
          //firebase .orderBy is not sorting yet case insensitive
          this.recipes = snapData.sort((a, b) => {
            if(!a.naam || !b.naam){
              return 1
            }
            if(a.naam.toLowerCase() < b.naam.toLowerCase()){
              return -1;
            } else {
              return 1;
            } 
          });
          this.filteredRecipes = this.recipes
          this.countSelected()
          this.filterRecipes(this.selectedFilterTags)
          activeUserRef.collection("shoppingLists").doc("active").get().then( shpLstDoc => {
              const data = shpLstDoc.data()
              this.shoppingListRecipes = (data && data.recipes) ? data.recipes : []
              this.shoppingList = (data && data.items) ? data.items : []
            })
        })
      }
    }
  },
  /**
   * 
   * DB.user.recipes.onSnapshot => snapshot 
   *  this.recipes = []
   *  for[snapshot] => doc
   *    recipe = doc.data()
   *    recipe.id = data.id
   *    this.recipes.push(recipe)
   */
  mounted: function() {
    if(this.activeUserID === null) {
      this.$router.push("/login");
    }
    this.loadData()
  },
  unmounted: function() {
    this.unsubscribe();
  },
};
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

