<template>
   <b-modal id="modalUpdateIngredient" modal-class="modal-fullscreen" title="Pas een ingredient aan" hide-backdrop
      size="md"  
      @ok="handleIngredientModalOk"
      @cancel="handleCancel">
      <b-form ref="ingredientForm" @submit.stop.prevent="modalUpdateIngredientSubmit()" >
        <b-form-group label="ingredient" invalid-feedback="Selecteer een ingredient." >
          <b-form-select class="col-8 mt-sm-0" 
            v-model="selectedIngredient" :options="ingredients" />
        </b-form-group>
        <b-form-group label="naam:" invalid-feedback="Geef een naam op." :state="naamState">
          <b-form-input class="col-6 px-1 px-sm-2" required :state="naamState"
            v-model="selectedIngredient.naam" />
        </b-form-group>
        <b-form-group label="meervoud:">
          <b-form-input class="col-6 px-1 px-sm-2" 
            v-model="selectedIngredient.meervoud" />
        </b-form-group>
        <b-form-group label="categorie:">
          <b-form-input class="col-6 px-1 px-sm-2" 
            v-model="selectedIngredient.categorie" />
        </b-form-group>
        <b-form-group label="categorie:">
          <p>{{selectedIngredient.id}}</p>
        </b-form-group>
      </b-form>
   </b-modal>
</template>

<script>
import db from "../db.js";
export default {
   name:"modal-update-ingredient",
   props: ["user", "userID", "ingredients"],
   data: function() {
      return {
         recipes: [],
         unsubscribe: null,
         naamState: null,
         selectedIngredient: {}
      };
   },
   computed: {
      ingredientId: function() {
         return (this.selectedIngredient.naam
         .toLowerCase()
         .replace(/[^\w ]+/g, '')
         .replace(/ +/g, '-') + "-" + this.now())
      },
   },
   methods: {
      handleIngredientModalOk: function(bvModalEvent) {
         bvModalEvent.preventDefault();
         this.modalUpdateIngredientSubmit();
      },
      handleCancel: function() {
         this.selectedIngredient = {}
      },
      /**
       * 1) check of het veld naam van ingredient is ingevuld
       * 2) zet categorie op "diversen" als hij niet ingevoerd is
       * 3) roep functie voegIngredientToe aan
       * 4) sluit het venster 
       */
      modalUpdateIngredientSubmit: function() {
         this.$refs.ingredientForm.checkValidity()
         this.naamState = this.selectedIngredient.naam != ""
         if(! this.naamState ) {
            return
         }
         //this.selectedIngredient.id = this.ingredientId
         if(this.selectedIngredient.categorie == "") {
            this.selectedIngredient.categorie = "diversen"
         }
         let ref = db.collection('ingredient').doc(this.selectedIngredient.id)
         ref.update(this.selectedIngredient).then( ()=> {
            this.$nextTick(() => {
               this.selectedIngredient = {}
               this.$bvModal.hide('modalUpdateIngredient')
            })
         })
         
      },
      now: function() {
         var date = new Date();
         return (
            String (date.getHours()) + 
            String (date.getMinutes()) + 
            String (date.getSeconds()) 
         ) 
      },
   },
}
</script>
