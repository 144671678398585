<template >
<b-container>
  <b-tabs class="mt-5 py-3" content-class="mt-3">
    <b-tab title="Admin"><adminMain :user="user" @showToast="showToast" /></b-tab>
    <b-tab title="Migrate ingredients"><admin-migrate-recipes :user="user" :ingredients="ingredients" /></b-tab>
  </b-tabs>
</b-container>
</template>
<script>
import AdminMigrateRecipes from "../components/AdminMigrateRecipes"
import AdminMain from "../components/AdminMain"
export default {
  name: "admin",
  props: ["user", "ingredients"],
  components: {AdminMigrateRecipes, AdminMain},
  methods: {
    showToast:function(event) {
      this.$emit("showToast", event)
    }
  }
}
</script>

