<template>
  <b-container fluid class="p-0 m-0 "  >
    <b-row class="m-0 p-0">
      <b-col class="m-0 p-0">
        <b-carousel
          id="carousel-fade"
          style="text-shadow: 0px 0px 2px #000"
          class="shadow p-0 m-0"
          fade 
          indicators
          :interval="10000"
          img-width="1024"
          img-height="480">
            <b-carousel-slide @click="navigateTo('recipe-list')"
              caption="Groente pizza"
              img-src="https://firebasestorage.googleapis.com/v0/b/one-time-shopping2-8d136.appspot.com/o/images%2Fcarousel%2F1.jpg?alt=media&token=efd167aa-6018-4a3b-9910-35f7252f8706"
            ></b-carousel-slide>
            <b-carousel-slide
              caption="Vegetarische risotto"
              img-src="https://firebasestorage.googleapis.com/v0/b/one-time-shopping2-8d136.appspot.com/o/images%2Fcarousel%2F2.jpg?alt=media&token=a0958b5b-b24e-422f-ac06-30666163b38c"
            ></b-carousel-slide>
            <b-carousel-slide
              caption="Coq au vin"
              img-src="https://firebasestorage.googleapis.com/v0/b/one-time-shopping2-8d136.appspot.com/o/images%2Fcarousel%2F3.jpg?alt=media&token=ba469a73-b6a1-4f92-bb20-fdf8b88e423e"
            ></b-carousel-slide>
        </b-carousel>
        <b-row class="justify-content-center text-center ">
          <b-col class="p-5 " >
            <h4 class="display-4 text-secundary mb-2">Shookr</h4>
            <router-link
              class="btn btn-outline-primary m-2"
              to="/register"
              v-if="!user"
            >Register</router-link>
            <router-link
              class="btn btn-outline-primary m-2"
              to="/login"
              v-if="!user"
            >Log In</router-link>
            <router-link 
              class="btn btn-primary m-2"
              to="/recipe-list"
              v-if="user"
            >Recepten</router-link>
            <router-link
              class="btn btn-primary m-2"
              to="/shopping-list"
              v-if="user"
            >Boodschappenlijstje</router-link>
            <router-link
              class="btn btn-primary m-2"
              to="/admin"
              v-if="user && roles && roles.includes('admin')"
            >Admin</router-link>
            <p class="lead">
              Shookr is jouw handige kookwebsite, menuplanner en boodschappenlijstje in één. 
              Je kunt er je favorieten recepten opslaan, aanpassingen maken, inplannen met hoeveel personen je gaat eten en je boodschappenlijstje aanmaken voor meerdere dagen en recepten.
              Met Shookr kun je in een keer al je boodschappen voor de hele week op een online- of uitgeprint lijstje zetten om in één keer al je boodschappen in te slaan.
              Geen onnodige tijdverspilling meer met elke dag je boodschappen halen. 
              In plaats daarvan alles in één keer in huis hebben.
              Omdat je je lijstje kunt delen, kan iedereen in de winkel met zijn mobiel meehelpen of kun je de boodschappen onderling verdelen.
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "home",
  props: ["user", "roles"],
  methods: {
    navigateTo: function(target) {
      console.log(target)
      this.$router.push(target);
    }
  }
};
</script>
