<template>
   <b-modal id="modalAddOperation" modal-class="modal-fullscreen" title="Voeg een bewerking toe" hide-backdrop
      size="md"  
      @show="focusInput"
      @hidden="cleanupModal"
      @ok="handleOperationModalOk">
      <b-form ref="operationForm" @submit.stop.prevent="modalAddOperationSubmit()" >
        <b-form-group label="naam:" :invalid-feedback="naamInvalidInfo" :state="naamState">
          <b-form-input ref="inputNaam" class="col-6 px-1 px-sm-2" required :state="naamState"
            v-model="newOperation.naam" />
        </b-form-group>
      </b-form>
   </b-modal>
</template>
<script>

import db from "../db.js"

export default {
   name:"modal-add-operation",
   props: ["userID", "operations"],
   data: function() {
      return {
         recipes: [],
         unsubscribe: null,
         naamState: null,
         naamInvalidInfo: "Deze naam kan niet toegevoegd worden",
         newOperation: {id:null ,naam: ""}
      };
   },
   computed: {
      operationId: function() {
         return (this.newOperation.naam
         .toLowerCase()
         .replace(/[^\w ]+/g, '')
         .replace(/ +/g, '-') + "-" + this.now)
      },
      now: function() {
         var date = new Date();
         return (
            String (date.getHours()) + 
            String (date.getMinutes()) + 
            String (date.getSeconds()) 
         ) 
      },
   },
   methods: {
    focusInput: function() {  
      setTimeout(() => {
          this.$refs.inputNaam.focus()
      },500)
    },
      handleOperationModalOk: function(bvModalEvent) {
         bvModalEvent.preventDefault();
         this.modalAddOperationSubmit();
      },
      handleCancel: function() {
         this.newOperation = {id:null ,naam: ""}
      },
      /**
       * 1) check of het veld naam van operation is ingevuld
       * 2) check of operation niet al bestaat
       * 4) voeg het operation aan de DB toe onder local operations
       * 5) dispatch 'operationCreated' event
       * 6) sluit het venster 
       */
      modalAddOperationSubmit: function() {
         this.naamState = true
         this.$refs.operationForm.checkValidity()
         this.newOperation.naam = this.newOperation.naam.trim()
         if(this.newOperation.naam == "") {
            this.naamState = false;
            this.naamInvalidInfo = "Geef een naam op"
         } else {
            for (let i = 0; i < this.operations.length; i++) {
               const oprtn = this.operations[i];
               if(oprtn.text == this.newOperation.naam) {
                  this.naamInvalidInfo = "Bewerking bestaat al"
                  this.naamState = false;
                  break;
               }
            }
         }
         if(!this.naamState ) {
            return
         }
         this.newOperation.id = this.operationId
         const oprtnRef = db.collection("users").doc(this.userID).collection("operations")
         oprtnRef.add(this.newOperation).then((oprtnDoc)=>{
               console.log(oprtnDoc)
               //find exact new object in operations to make matching possible in dropdown of ModalRecipeOperation
               for (let i = 0; i < this.operations.length; i++) {
                  const oprtn = this.operations[i];
                  if(oprtn.value.naam == this.newOperation.naam) {
                     this.newOperation = oprtn.value
                     this.newOperation.id = oprtnDoc.id
                     break;
                  }
               }
               //save the id in the newly created operation so it can be found back in recipes when it get's updated
               oprtnRef.doc(this.newOperation.id).set(this.newOperation).then( ()=> {
                     //no need to add to operations because the onSnapshot in App.js will add it to the clients operations array
                     this.$emit("operationCreated", this.newOperation)
                     //this.newOperation = {id:null ,naam: "", meervoud: "", categorie: ""}
                     this.$nextTick(()=> {
                        this.$bvModal.hide('modalAddOperation')
                     })
               })
               
               
         })
         .catch(error => {
            console.log(error)
         })
      },
      cleanupModal: function() {
            this.newOperation = {id:null ,naam: ""}
      }
   },
}
</script>
