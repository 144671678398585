<template>
   <transition-group tag="ul" name="mylist" class="list-group">
      <b-list-group-item v-for="item in filteredRecipes" 
         :key="item.id" @click="navigateTo('/recipe-detail/' + item.id)"
         class="mylist-item py-2 mb-0 border-1">
         <b-row >
            <b-col v-if="editable" class="col-auto p-0 m-0" >
               <b-form-checkbox size="lg" v-model="item.selected" @change="$emit('countSelected', item)" class="form-check-input position-static ml-1 py-0 m-0"/>
            </b-col>
            <b-col v-if="item.image" cols="auto" class="col-auto p-0 ml-2" >
               <thumb :imageName="item.image" :alt="item.name" />
            </b-col>
            <b-col class="text-wrap px-0 ml-0 ml-2"  >
                  <h5>{{ item.naam }}</h5>
                  <p><i>{{getTagText(item.tags)}}</i></p>
            </b-col>
         </b-row>
      </b-list-group-item>
   </transition-group>
</template>
<script>
import Thumb from "../components/Thumb"
export default {
   name: "list-recipes",
   components: {Thumb},
   data: function() {
      return {

      }
   },
   props: ['filteredRecipes', 'editable'],
   methods: {
      getTagText: function(tags) {
         if(!tags) return "";
         let text = ""
         for (let i = 0; i < tags.length; i++) {
            const tag = tags[i];
            text += (i != 0 ? " * " : "") + tag.name
         }
         return text
      },
      navigateTo: function(target) {
         if(!this.editable){
            this.$router.push(target);
         }
      }
   }
}
</script>
<style>
   .thumb {
      width: 130px;
      height: 100px;
      object-fit: cover;
   }
   .mylist-item {
      transition: all 0.7s;
   }
   .mylist-enter, .mylist-leave-to {
      opacity: 0;
   }
   .mylist-leave-active {
      /* position: absolute; */
      height: 0;
      padding-top: 0;
      padding-bottom: 0;
      opacity: 0;
      font-size: 0;
      color: transparent;
   }
</style>

