import firebase from "firebase";


var firebaseConfig = {
  apiKey: "AIzaSyDex1savUpkBsGp1T7mN0CSyaRZIx70vr4",
  authDomain: "one-time-shopping2-8d136.firebaseapp.com",
  projectId: "one-time-shopping2-8d136",
  storageBucket: "one-time-shopping2-8d136.appspot.com",
  messagingSenderId: "497346799477",
  appId: "1:497346799477:web:261244b0b87a1c8184153c"
};
// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
export default firebaseApp.firestore();
