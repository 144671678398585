<template>
  <div>
    <b-navbar class="shadow fixed-top" toggleable="lg" type="dark" variant="primary" v-if="showNavigation" >
      <b-navbar-brand href="#" @click="navigateToTarget('/home')">Shookr</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto"  >
          <b-nav-item v-if="!isAuthorizedUser(user) && this.$route.path=='/register'" href="#" class="mr-2"
          right @click="navigateToTarget('/login')" >Login</b-nav-item>
          <b-nav-item v-if="!isAuthorizedUser(user) && this.$route.path=='/login'" href="#" class="mr-2"
          right @click="navigateToTarget('/register')" >Register</b-nav-item>
          <b-nav-item v-if="isAuthorizedUser(user)" :disabled="recipeListActive" href="#" class="mr-1" 
            @click="navigateToTarget('/recipe-list')" ><b-icon icon="list-ul" /> Recepten</b-nav-item>
          <b-nav-item v-if="isAuthorizedUser(user)" :disabled="shoppingListActive" href="#" class="mr-1" 
            @click="navigateToTarget('/shopping-list')" ><b-icon icon="basket2-fill" /> Boodschappen</b-nav-item>
          <b-nav-item-dropdown v-if="isAuthorizedUser(user)" right :text="user.displayName" 
            toggle-class="nav-link-custom" variant="outline" >
                                  <!-- :active="item.status=='active'" -->
            <b-dropdown-item v-for="item in shoppingLists" @click="$emit('switchRecipeShoppingList',item.uid)" :key="item.uid" ><b>{{item.sharedName}}</b></b-dropdown-item>
            <div role="separator" class="dropdown-divider"></div>
            <b-dropdown-item @click="$emit('openModalInviteUser')"  >Uitnodigen</b-dropdown-item>
            <b-dropdown-item @click="$emit('openModalAcceptInvitations')" >Uitnodigingen
              <b-badge v-if="isAuthorizedUser(user) && invitationCount>0" 
                pill variant="danger" 
                class="ml-2">{{invitationCount}}</b-badge></b-dropdown-item>
            <b-dropdown-item v-if="roles && roles.includes('admin')"  @click="navigateToTarget('/admin')">Admin</b-dropdown-item>
            <div role="separator" class="dropdown-divider"></div>
            <b-dropdown-item @click="$emit('logout')">
              Log uit</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-badge v-if="isAuthorizedUser(user) && invitationCount>0" 
            pill variant="danger" class="mt-2 mb-3">{{invitationCount}}</b-badge><p style="color:#ffffff;opacity: 0.5;"><small>v2.79</small></p>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<script>
import db from "../db.js"
export default {
  name: "Navigation",
  data: function() {
    return {
      shoppingList: null,
      shoppingListID: null,
      recipeListActive: false,
      shoppingListActive: false,
      showNavigation: true
    };
  },
  props: ["user", "invitationCount", "roles", "shoppingLists", "activeShoppingListName", "isAdmin"],
  methods: {
    navigateToTarget: function(target) {
      
      if(this.user) {
        console.log(target)
        const userRef = db.collection("users").doc(this.user.uid)
        console.log(userRef)
        userRef.update({routerPath: target}).then(()=>{
          console.log(target)
          this.pushRouterTarget(target)
        }).catch(error => {
          console.log(error)
        })
      } else {
        this.pushRouterTarget(target)
      }
    },
    isAuthorizedUser: function(user) {
      return Boolean(user) && (user.emailVerified || this.isAdmin)
    },
    pushRouterTarget: function(target) {
      if (this.$route.path != target){
        this.$router.push(target);
      }
    }
  },
  mounted: function() {
    this.$router.afterEach((to) => {
      this.recipeListActive = (to.name == "recipe-list")
      this.shoppingListActive = (to.name == "shopping-list")
      this.showNavigation = (to.name != "home")
    })
  }
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #4262b9;
}

.dropdown-dark {
  color:#999999;
}

</style>
