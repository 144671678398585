<template>
    <b-modal id="modalAddShoppingItem"
      modal-class="modal-fullscreen" 
      title="Voeg een ingredient toe" 
      hide-backdrop
      hide-footer
      size="md" 
      @show="focusInput"
      @hidden="cleanupModal"
      @ok="handleShoppingItemModalOk">
      <b-form ref="shoppingItemForm" @keydown.enter="handleShoppingItemModalOk" @submit.stop.prevent="modalAddShoppingItemSubmit()" >
        <b-form-group label="naam:" :invalid-feedback="naamInvalidInfo" :state="naamState">
          <b-form-input ref="inputNaam" class="col-6 px-1 px-sm-2" required :state="naamState"
            v-model="newShoppingItem.naam" />
        </b-form-group>
        <b-form-group label="categorie:">
          <b-form-input class="col-6 px-1 px-sm-2" 
            v-model="newShoppingItem.categorie" />
        </b-form-group>
        <b-row class="border-0" align-h="end">
         <b-col cols="3" class="border-0" >
            <b-button class="mt-3" variant="primary"  @click="handleShoppingItemModalOk">OK</b-button>
         </b-col>
        </b-row> 
      </b-form>
    </b-modal>
</template>
<script>

import db from "../db.js"

export default {
    name: 'modal-add-shopping-item',
    props: ["userID"],
    data() {
        return {
         unsubscribe: null,
         naamState: null,
         naamInvalidInfo: "Deze naam kan niet toegevoegd worden",
         newShoppingItem: {naam: "", meervoud: "", categorie: "diversen"}
        }
    },
    methods:{
    focusInput: function() {  
      setTimeout(() => {
          this.$refs.inputNaam.focus()
      },500)
    },
      handleShoppingItemModalOk: function(bvModalEvent) {
         bvModalEvent.preventDefault();
         this.modalAddShoppingItemSubmit();
      },
      handleCancel: function() {
         this.newShoppingItem =  {naam: "", meervoud: "", categorie: "diversen"}
      },
      modalAddShoppingItemSubmit: function() {
         this.naamState = true
         this.$refs.shoppingItemForm.checkValidity()
         this.newShoppingItem.naam = this.newShoppingItem.naam.trim()
         if(this.newShoppingItem.naam == "") {
            this.naamState = false;
            this.naamInvalidInfo = "Geef een naam op"
         }
         if(!this.naamState ) {
            return
         }
         if(this.newShoppingItem.categorie == "") {
            this.newShoppingItem.categorie = "diversen"
         }
         this.newShoppingItem.meervoud = ""
         const shpItmRef = db.collection("users").doc(this.userID)
            .collection("shoppingLists").doc('active');
         shpItmRef.get().then(doc => {
            let data = doc.data();
            !data.items ? data.items = [] : false;
            //this is kind of hacky should be replaced
            data.items.push(
                {
                    aantal:1, 
                    ingredient:this.newShoppingItem, 
                    eenheid:{
                        afkorting: "st.",
                        id: "tMoR9K8P7BdmyvrbjWfP",
                        meervoud: "stuks",
                        naam: "stuk",
                    },
                    selected: false,
                })
            console.log(data)
            shpItmRef.set(data).then(()=>{
                this.cleanupModal()
                this.$bvModal.hide('modalAddShoppingItem')
            })
         .catch(error => {
            console.log(error)
         })
         })
         .catch(error => {
            console.log(error)
         })
      },
      cleanupModal: function() {
            this.newShoppingItem = {naam: "", meervoud: "", categorie: "diversen"}
      }

    }
}
</script>