<template>
  <div>
    <h1>Reset Password</h1>
    <form class="py-3 mt-5" @submit.prevent="resetPassword">
      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" v-model="email" class="form-control" id="email" placeholder="Enter email">
      </div>
      <button type="submit" class="btn btn-primary">Reset Password</button>
    </form>
    <p class="text-center mt-2">
      or
      <router-link to="/login">login</router-link>
    </p>
  </div>
</template>
<script>
import firebase from "firebase";
export default{
  name:"password-forget",
  data:function() {
    return {
      email: "",
      error:""
    }
  },
  methods: {
    resetPassword: function() {
      const info = {
        email: this.email
      }
      firebase.auth().sendPasswordResetEmail(info.email).then(function() {
        // Email sent.
        alert("Email sent");
      }).catch(function(error) {
        // An error happened.
        alert(error.message);
      });
    }
  }
}
</script>