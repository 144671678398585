<template>
  <b-container class=" mt-2 mt-lg-5 mb-5 p-0">
    <b-row class=" justify-content-center no-gutters">
      <b-col class="col-12 mt-5">
        <b-card class="rounded-sm bg-light"  header-tag="header">
          <template #header >
            <b-row class="border-0 ">
              <b-col  class="col-8 col-lg-6" >
                  <h5 ref="header" class="mb-0 mt-0" v-if="!editable">{{recipe.naam}}</h5><i v-if="!editable">{{kCalTotal>0 ? kCalTotal + ' kCal pp' : '' }}</i>
                  <b-form-input v-if="editable" :style="'height: ' + headerHeight + 'px'"  @update="submitRecipeTimeout(recipe)" class="bg-transparent border-primary"  size="md" placeholder="Receptnaam" v-model="recipe.naam"/><i v-if="editable">{{kCalTotal>0 ? kCalTotal + ' kCal pp' : '' }}</i>
              </b-col>
              <!-- desktop variant -->
              <b-col cols="auto" class="ml-auto d-none d-lg-block mt-2 mt-sm-0 ">
                <b-button :variant="editable ? 'secondary ml-2' :'primary ml-2'"
                  title="Receptgegevens aanpassen" :pressed="false"
                  @click="editable = !editable, draggableList = false" >
                    <b-icon :icon="editable? 'pencil-fill': 'pencil-fill'" :variant="editable? 'dark' : 'white'"/> {{!editable ? 'Aanpassen' : 'Klaar'}}</b-button >
              </b-col>
              <!-- mobile variant -->
              <b-col cols="auto" class="ml-auto d-block d-lg-none p-0 mt-auto ">
                <b-button class="ml-2 mr-2" @click="$router.push('/recipe-list');" :disabled="editable" :variant="editable ? 'secundary' :'primary'">
                  <b-icon :icon="editable? 'list-ul': 'list-ul'" /></b-button>
                <b-button class="ml-2 mr-2" @click="$router.push('/shopping-list');" :disabled="editable" :variant="editable ? 'secundary' :'primary'">
                  <b-icon :icon="editable? 'basket2': 'basket2-fill'" /></b-button>
              </b-col>
            </b-row>
          </template>
          <b-card-body class="card-body py-2 px-0 ">
            <b-container >
              <b-row  >
                <b-col class="col-9 col-md-6 px-0 ml-0 mr-0 " >
                  <b-row class="border-0">
                    <b-col cols="auto" :class="editable ? 'px-0 ml-4' : 'px-0 ml-3 rounded border'" >
                      <b-button v-if="!editable" variant="primary" class="mr-2" ><b-icon icon="dash" @click="calcEaterChange(--numberOfEaters)" variant="white"/></b-button>
                      <strong><span v-if="!editable">{{numberOfEaters}} </span>
                      <b-form-spinbutton class="mr-2 border-primary text-primary" id="sb-inline" v-if="editable" 
                        v-model="numberOfEaters" @change="changeRecipeRatio(numberOfEaters)" inline/>
                        <span class="ml-0" v-if="!editable">{{numberOfEaters == 1 ? "persoon" : "personen"}}</span>
                        <span class="ml-0" v-if="editable">{{numberOfEaters == 1 ? "portie" : "porties"}}</span></strong>
                        <b-button v-if="!editable" variant="primary" class="ml-2" ><b-icon icon="plus" @click="calcEaterChange(++numberOfEaters)" variant="white"/></b-button>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="auto" class="ml-auto d-block d-lg-none px-0 border-0" >
                  <b-button  :variant="editable ? 'secondary' : 'primary'"
                    title="Receptgegevens aanpassen" :pressed="false"
                    @click="editable = !editable, draggableList = false" >
                      <b-icon class="mr-1" :icon="editable? 'pencil-fill': 'pencil-fill'" :variant="editable? 'dark' : 'default'"/>{{!editable ? 'Edit' : 'Klaar'}}</b-button >
                </b-col>
              </b-row>
            </b-container>
          </b-card-body>
          <b-container >
            <b-row >
              <b-col class=" px-0 col-12 ">
                <b-row >
                  <b-col class="col-12 col-lg-6" order-lg="2">
                    <b-img class="mb-3" v-if="imageURL" :src="imageURL" rounded fluid :alt="recipe.naam" />
                    <div :hidden="!editable" class="border border-primary rounded p-0" >
                      <b-form-file
                        v-model="uploadURL"
                        :state="uploadURL"
                        placeholder="Selecteer een foto of sleep 'm erin..."
                        drop-placeholder="Sleep de foto hier naartoe..."
                        accept="image/*"/>
                    </div>
                  </b-col>
                  <b-col order-lg="1">
                    <b-form-group >
                      <b-row>
                        <b-col cols="auto" class="ml-auto px-0 mr-3 mt-2" >
                          
                            <b-button variant="secondary" v-if="editable" 
                            title="Ingredient aanmaken" :pressed="false"
                            @click="toggleListModus()" class="ml-3 text-dark" >
                              <b-icon class="mr-2" icon="arrow-down-up" variant="dark"  ></b-icon>{{draggableList ? "Gereed" : "Volgorde"}}
                          </b-button>
                          <b-button variant="secondary" v-if="editable" 
                            title="Ingredient aanmaken" :pressed="false"
                            @click="createIngredient" class="ml-3 text-dark" >
                              <b-icon class="mr-2" icon="plus-circle" variant="dark"  ></b-icon>Add
                          </b-button>
                        </b-col>
                      </b-row>
                      <b-row >
                        <b-col cols="auto" class="mr-auto ml-4 px-0">
                          <b-button @click="toggleSelected" 
                            variant="white" size="sm" >
                            <b-icon :icon="allSelected ? 'check-square' : 'square'"  style="color: #666666;"/>
                          </b-button>
                        </b-col>
                        <b-col class="px-0 ml-0 mt-1 mb-2" style="color:#333333">
                          {{allSelected ? 'Selecteer niets' : 'Selecteer alles'}}
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="ml-4 mr-4" style="background: #DDDDDD; height: 2px"></b-col>
                      </b-row>
                      <b-list-group class="rounded-lg bg-light list-group-flush border-0 mt-0 mb-2 ml-0" v-if="!draggableList" >
                        <b-list-group-item v-for="item in recipeIngredients" 
                          :class="editable ? 'd-flex bg-light border-0 py-1' : 'd-flex bg-light border-0 py-1'"
                          v-cloak :key="item.id">
                          <b-checkbox :disabled="editable" size="lg" v-model="item.selected"/>
                          <b-link v-b-modal.modal-center v-b-modal.modal-no-backdrop size="lg"
                            :class="editable ? 'align-self-center text-primary text-truncate' : 'align-self-center text-dark text-truncate' " 
                            :style="editable ? 'text-decoration: underline' : 'text-decoration: none'" href="#"
                            @click="showIngredientModal(item)" :disabled="!editable">
                            {{ getIngredientText(item) }}</b-link>
                        </b-list-group-item>
                          <!-- desktop variant -->
                          <b-col cols="auto" class="ml-auto d-none d-lg-block mt-2 mt-sm-0 px-2 ">
                                          <b-button class="ml-2 mt-2 mr-2" @click="addRecipeToShoppingList(recipe)" :disabled="selectedRecpIngrCount == 0" :hidden="editable" :variant="editable ? 'secundary' :'primary'">
                                          <b-icon :icon="editable? 'basket2': 'basket2-fill'" /> Voeg {{selectedRecpIngrCount}} items toe aan de boodschappenlijst</b-button>
                          </b-col>
                          <!-- mobile variant -->
                          <b-col cols="auto" class="ml-auto d-block d-lg-none p-0 px-2 mt-auto ">
                                          <b-button class="ml-2 mt-2 mr-2 btn-block ph-2" @click="addRecipeToShoppingList(recipe)" :disabled="selectedRecpIngrCount == 0" :hidden="editable" :variant="editable ? 'secundary' :'primary'">
                                          <b-icon :icon="editable? 'basket2': 'basket2-fill'" /> Voeg {{selectedRecpIngrCount}} items toe</b-button>  
                          </b-col>
                      </b-list-group>
                      <draggable class="list-group" tag="ul" v-model="recipeIngredients"  v-if="draggableList"
                        v-bind="dragOptions"
                        @start="drag = true"
                        @update="saveIngredients(true)"
                        @end="drag = false" >
                          <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                            <div v-for="item in recipeIngredients" class="align-self-center text-primary text-truncate mt-1 py-2" :key="item.id">
                                  <b-icon class="mr-2" icon="justify" variant="primary"/>{{ getIngredientText(item) }}
                            </div>
                          </transition-group>
                      </draggable>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-form @submit.stop="submitRecipe(recipe)" >
                  <b-form-group class="px-0"
                    id="input-group-1"
                    label-for="beschrijvingArea"
                    description="Deel je recepten en boodschappenlijst met een huisgenoot!"> 
                    <b-row v-if="editable" class="border-0">
                      <!-- mobile variant -->
                      <b-col cols="auto" class="ml-auto d-block d-xl-none py-2 ">
                        <b-button  :variant="editable ? 'secondary' :'primary'"
                          title="Receptgegevens aanpassen" :pressed="false"
                          @click="$bvModal.show('modalRecipeDescription')" >
                            <b-icon :icon="editable? 'pencil-fill': 'pencil'" :variant="editable? 'dark' : 'default'"/></b-button >
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="d-block d-xl-none">
                        <b-form-textarea 
                          class="bg-light rounded-0"
                          id="beschrijvingArea"
                          v-model="recipe.beschrijving"
                          style="font-size:24px"
                          :plaintext="true"
                          rows="3" 
                          max-rows="100"/>
                      </b-col>
                    </b-row>
                    <!-- desktop variant -->
                    <b-row>
                      <b-col class="d-none d-xl-block mt-4 mb-4">
                        <b-form-textarea 
                          class="bg-light"
                          id="beschrijvingArea"
                          v-model="recipe.beschrijving"
                          :plaintext="!editable"
                          @focusout="submitRecipeTimeout(recipe)"
                          size="lg"
                          rows="3" 
                          max-rows="15"/>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group v-if="roles && roles.includes('admin') && editable" label-for="tags-validation" :state="state">
                    <b-form-tags 
                        input-id="tags-validation"
                        v-model="selectedTags"
                        :input-attrs="{ 'aria-describedby': 'tags-validation-help' }"
                        :tag-validator="tagValidator"
                        :state="state"
                        placeholder="Voeg tag toe.."
                        separator=" ,;"
                        remove-on-delete />
                    <template #invalid-feedback>
                        {{tagError}}
                    </template>
                    <template #description>
                        <div id="tags-validation-help">
                          Geef één of meerdere bestaande tags gescheiden door spaties, komma's of punt-komma's
                        </div>
                    </template>
                  </b-form-group> 
                </b-form>
                <b-row v-if="editable" >
                  <b-col cols="auto" class="ml-auto px-0 mr-3" >
                    <b-button v-b-modal.modal-multi-3 variant="danger"  ><b-icon icon="trash" /> Verwijder recept</b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>

    <modal-recipe-ingredient 
      :userID="activeUserID"
      :recipeID="recipeID"
      :recipe="recipe"
      :selectedIngredient="selectedIngredient"
      :ingredients="ingredients" 
      :operations="operations"
      :nevoIngredients="nevoIngredients"
      :units="units" 
      :eaters="this.numberOfEaters"
      @createIngredient="submitCreatedIngredient"
      @updateIngredient="submitUpdatedIngredient"
      @deleteIngredient="submitDeleteIngredient"/>

    <modal-recipe-description
      :userID="userID" 
      :recipe="recipe" 
      @submitRecipe="submitRecipe"/>

    <b-modal title="Recept verwijderen" hide-backdrop
        id="modal-multi-3" size="md"  
        @ok="deleteRecipe()">
        <p class="my-1">Weet je zeker dat je het recept '{{recipe.naam}}' wilt verwijderen?</p>
        <template #modal-footer="{ ok, cancel }">
        <b-button variant="primary" @click="cancel()">
          Cancel
        </b-button>
        <b-button variant="danger" @click="ok()">
          OK
        </b-button>
        </template>
    </b-modal>

  </b-container>
</template>
<script>
import db from "../db.js";
import ModalRecipeIngredient from "../components/ModalRecipeIngredient"
import ModalRecipeDescription from "../components/ModalRecipeDescription"
import draggable from 'vuedraggable'
import firebase from "firebase"
const EMPTY_INGREDIENT = {calculatedNumber:1, aantal: 1, eenheid: {}, ingredient: {}, bewerking: {}}
export default {
  name: "recipe-detail",
  data: function() {
    return {
      recipe: {},
      recipeModalTabIndex: 0,
      selectedIngredient: EMPTY_INGREDIENT,
      numberOfPersons: null,
      numberOfEaters: null,
      eaterPersonRatio: 1,
      recipeIngredients: [],
      recipeID: this.$route.params.recipeID,
      ingrNumberInput: null,
      cancelSubmitRecipeTimeout: null,
      drag: false,
      draggableList: false,
      unsubscribe: null,
      selectedTags: [],
      tagIDs: [],
      tagsDirty: false,
      tagsAllFound: null,
      validateTags: false,
      tagError: "",
      uploadURL: null,
      imageURL: null,
      editable: null,
      shoppingList: [],
      shoppingListRecipes: [],
      headerHeight: 20,
      kCalTotal: ""
    };
  },
  props: ["user", "userID", "activeUserID", "units", "ingredients", "operations", "tags", "roles", "nevoIngredients"],
  components: {ModalRecipeIngredient, ModalRecipeDescription, draggable},
  watch: {
    selectedTags(newTags) {
      if(!this.validateTags) return;
      this.tagsDirty = newTags.length == 0 ? false : true ;
      this.tagIDs = []
      this.tagsAllFound = true
      this.tagError = "Niet gevonden tag(s): "
      for (let i = 0; i < newTags.length; i++) {
        const newTag = newTags[i];
        var found = false
        for (let j = 0; j < this.tags.length; j++) {
          const tag = this.tags[j];
          if(newTag.toLowerCase() == tag.text.toLowerCase()) {
            found = true
            break
          }
        }
        if(!found) {
          this.tagError = this.tagError + newTag + " "
          this.tagsAllFound = false
        }
      }
      if(this.tagsAllFound) {
        this.saveSelectedTags(this.selectedTags)
      }
    },
    uploadURL(value) {
      if(value) {
        try {
          console.log(value)
          const imageName = this.getImageName(this.recipe.naam, this.activeUserID, this.recipeID);
          const imageRef = this.getImageRef(imageName);
          console.log(imageRef.fullPath)
          console.log("For uploading implement rules to prevent abuse: https://firebase.google.com/docs/storage/security")
          //For uploading implement rules to prevent abuse: https://firebase.google.com/docs/storage/security
          imageRef.put(value).then( snapshot => {
            console.log("uploaded!", snapshot)
            const recipeRef = db.collection("users").doc(this.activeUserID).collection("recipes").doc(this.recipeID)
            recipeRef.update({image: imageName}).then( ()=> {
              this.loadImage(imageRef)
            })
            .catch((error) => {
              console.log(error.message)
            })
            
          })
          .catch( error => {
            console.log("File put error: " + error)
          })
        }
        catch(error) {
          console.log("Storage Error: " + error)
        }
      }
    }
  },
  computed: {
    state() {
        // Overall component validation state Can be true, false or null
        // this.tagsDirty ? this.selectedTags.length > 0  : null
        return this.tagsDirty ? this.selectedTags.length > 0 && this.tagsAllFound : null
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    selectedRecpIngrCount() {
      var count = 0
      if(this.recipeIngredients) {
        for (let i = 0; i < this.recipeIngredients.length; i++) {
          const ingr = this.recipeIngredients[i];
          count += (ingr.selected ? 1 : 0)
        }
      }
      return count
    },
    allSelected: function() {
      let selectCount = 0
      for (let i = 0; i < this.recipeIngredients.length; i++) {
        const rcpIngr = this.recipeIngredients[i];
        if(rcpIngr.selected) {
          selectCount++
        }
      }
      return selectCount == this.recipeIngredients.length
    }
  },
  methods: {
    toggleSelected: function() {
      if(this.allSelected) {
        this.selectNothing()
      } else {
        this.selectAll()
      }
    },
    selectAll: function() {
      for (let i = 0; i < this.recipeIngredients.length; i++) {
        const rcp = this.recipeIngredients[i];
        rcp.selected = true;
      }
    },
    selectNothing: function() {
      for (let i = 0; i < this.recipeIngredients.length; i++) {
        const rcp = this.recipeIngredients[i];
        rcp.selected = false;
      }
    },
    /**
     * 1) Loops through this ingredients
     * 2) Multiplies aantal with the eaterPersonRatio
     * 3) Loops through the shoppingList to match ingredient and unit.
     * 4) If found adds up aantal of units, if not found adds ingredient to the shoppingList
     * 
     * param {*} recipe 
     * for recipe[ingredients] => i
     *   i.aantal = r.eaterPersonRatio * i.aantal
     *   for [this.shoppingList] => sli
     *     if(i.ingredient == sli.ingredient && i.eenheid == sli.eenheid)
     *       sli.aantal += i.aantal
     *       found = true
     *   if(!found)
     *     this.shoppingList.push(clone(i))
     */
    addRecipeToShoppingList: function(recipe) {
      const rcp = Object.assign({},recipe);
      rcp.id = this.recipeID;
      rcp.recipeInstanceID = Date.now().toString(36);
      this.shoppingListRecipes.push(rcp)
      if (!recipe.eaterPersonRatio) {
        recipe.eaterPersonRatio = 1;
      }
      for (let j = 0; j < recipe.ingredients.length; j++) {
        if(!this.recipeIngredients[j].selected){ continue }
        const rcpItem = Object.assign({},recipe.ingredients[j]);
        rcpItem.aantal *= recipe.eaterPersonRatio;
        var found = false;
        for (let i = 0; i < this.shoppingList.length; i++) {
          const shpLstItem = this.shoppingList[i];
          if ( rcpItem.ingredient.id == shpLstItem.ingredient.id &&
            rcpItem.eenheid.id == shpLstItem.eenheid.id ) {
            found = true;
            shpLstItem.aantal += rcpItem.aantal;
            shpLstItem.selected = false;
            shpLstItem.recipeIDs.push({recipeInstanceID: rcp.recipeInstanceID, aantal: rcpItem.aantal})
          }
        }
        if (!found) { 
          rcpItem.recipeIDs = [{recipeInstanceID: rcp.recipeInstanceID, aantal: rcpItem.aantal}]
          this.shoppingList.push( rcpItem);
        }
      }
      const shoppingListRef = db.collection('users').doc(this.activeUserID).collection('shoppingLists').doc("active")
      shoppingListRef.update({items: this.shoppingList, recipes: this.shoppingListRecipes}).then(()=> {
        const toastObj = {
            title: `${recipe.naam} toegevoegd`,
            toaster: 'b-toaster-bottom-left',
            variant: 'warning',
            solid: true,
            appendToast: false
          }
        this.$emit("showToast",["'" + recipe.naam + "' toegevoegd aan boodschappenlijstje",toastObj] )
        this.pushRouterTarget('/shopping-list')
      })
    },
    pushRouterTarget: function(target) {
      if (this.$route.path != target){
        this.$router.push(target);
      }
    },
    tagValidator(tag) {
      // Individual tag validator function. Check all lowercase chars tag === tag.toLowerCase() &&
      return tag === tag.toLowerCase() && tag.length > 0 && tag.length <= 100
    },
    saveSelectedTags: function(selectedTags) {
      const ref = db.collection('users').doc(this.activeUserID).collection('recipes').doc(this.recipeID);
      var recipeTags = []
      for (let i = 0; i < selectedTags.length; i++) {
        const tagText = selectedTags[i];
        for (let j = 0; j < this.tags.length; j++) {
          const tag = this.tags[j];
          if(tagText.toLowerCase() == tag.text.toLowerCase()) {
            recipeTags.push(tag.value)
            break
          }
        }
      }
      ref.update({tags: recipeTags}).then(() => {
        this.draggableList = false
      })
    },
    loadImage(imageRef) {
      imageRef.getDownloadURL().then( url => {
        this.imageURL = url
      })
      .catch( error => {
        console.log(error)

        this.imageURL = this.getDefaultImageName()
      })
    },
    getImageRef(imageName) {
      const imagePath = "images/recipes/" + imageName;
      const storageRef = firebase.storage().ref()
      return storageRef.child( imagePath );
    },
    getImageName(recipeName, activeUserID, recipeID) {
      const slug = this.convertToSlug(recipeName)
      return slug + "-" + activeUserID + "-" + recipeID;
    },
    getDefaultImageName:function() {
      return "default-image.jpeg"
    },
    convertToSlug: function(text)
    {
        return text
            .toLowerCase()
            .replace(/[^\w ]+/g,'')
            .replace(/ +/g,'-')
            ;
    },
    getIngredientText: function(item) {
      var eenheid = item.calculatedNumber > 1 && item.eenheid.meervoud != "" ? item.eenheid.meervoud : item.eenheid.naam;
      var naam = item.calculatedNumber > 1 && item.ingredient.meervoud != "" ? item.ingredient.meervoud : item.ingredient.naam;
      
      var txt = ""
      //check if eenheid geen 'stuk' is anders weglaten, 
      //later anders doen namelijk: hidden prop in eenheid definieren
      if(item.eenheid.id != "tMoR9K8P7BdmyvrbjWfP" ) {
        txt = item.calculatedNumber + " " + eenheid + " " + naam
      } else {
        txt = item.calculatedNumber + " " + naam
      }
      var bewerking = item.bewerking ? item.bewerking.naam : false
      return txt + (bewerking ? " - " + bewerking : "");
    },
    createIngredient: function() {
      this.selectedIngredient = {calculatedNumber:null, aantal: null, eenheid: {}, ingredient: {}, bewerking: {}}
      this.$bvModal.show("modalRecipeIngredient")
    },
    submitCreatedIngredient: function(ingredient) {
      this.recipeIngredients.push(ingredient)
      this.initRecipeIngredients(this.recipeIngredients)
      this.calculateNutrients()
      this.saveIngredients()
    },
    submitUpdatedIngredient: function(ingredient) {
      console.log("submitUpdatedIngredient")
      for (let i = 0; i < this.recipeIngredients.length; i++) {
        const ingr = this.recipeIngredients[i];
        if(ingr.id == ingredient.id) {
          this.recipeIngredients[i] = ingredient
          break;
        }
      }
      this.calculateNutrients()
      this.saveIngredients()
    },
    submitDeleteIngredient: function(ingredient) {
      let i = -1
      for (i = 0; i < this.recipeIngredients.length; i++) {
        const ingr = this.recipeIngredients[i];
        if(ingr.ingredient.id == ingredient.ingredient.id) {
          break;
        }
      }
      this.recipeIngredients.splice(i,1)
      this.selectedIngredient = EMPTY_INGREDIENT
      this.calculateNutrients()
      this.saveIngredients()
    },
    deleteRecipe: function() {
      db.collection('users').doc(this.activeUserID)
        .collection('recipes').doc(this.recipeID)
        .delete()
        .then(() => {
          this.$bvModal.hide("recipeModal")
          this.$router.push("/recipe-list")
        })
    },
    toggleListModus: function() {
      this.draggableList=!this.draggableList
    },
    calculateNutrients:function() {
      let kCalTotal = 0
      for (let i = 0; i < this.recipeIngredients.length; i++) {
        const ingredient = this.recipeIngredients[i];
        if(ingredient.kCal_pp) {
          kCalTotal += ingredient.kCal_pp
        }
      }
      this.kCalTotal = Math.round(kCalTotal)
    },
    /**
     * Stores the order of the ingredients
     * TODO: look here for validator: https://json-schema.org/implementations.html
     */
    saveIngredients: function(keepDraggable = false) {
      console.log("saveIngredients")
      this.recipe.ingredients = this.recipeIngredients
      //remove unwanted selected prop from recipe
      delete this.recipe.selected
      const recipeDocRef = db.collection('users').doc(this.activeUserID).collection('recipes').doc(this.recipeID);
      recipeDocRef.update({ingredients:this.recipe.ingredients}).then( () => {
        //trigger binding
        this.recipeIngredients = [...this.recipeIngredients]
        this.draggableList = keepDraggable
      })
      .catch( error => {
        console.log("saveIngredients -> Error:")
        console.log(error)
      })
    },
    showIngredientModal: function(ingredient) {
      //duplicate object to be able to cancel changes
      this.selectedIngredient = Object.assign({}, ingredient)
      this.$bvModal.show("modalRecipeIngredient")
    },
    changeRecipeRatio: function(numberOfEaters) {
      this.numberOfEaters = numberOfEaters
      this.recipe.aantal_personen = this.numberOfPersons = numberOfEaters
      this.submitRecipe(this.recipe)
    },
    calcEaterChange: function(number) {
      if( number < 0 ) return;
      this.recipe.eaterPersonRatio = this.eaterPersonRatio = number / this.numberOfPersons
      this.submitRecipe(this.recipe)
      for (let i = 0; i < this.recipeIngredients.length; i++) {
        const ingredient = this.recipeIngredients[i];
        ingredient.calculatedNumber = this.round(this.eaterPersonRatio * ingredient.aantal,1)
      }
      this.saveIngredients()
    },
    round: function(value, precision) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    },
    submitRecipeTimeout: function(recipe) {
      clearTimeout(this.cancelSubmitRecipeTimeout)
      this.cancelSubmitRecipeTimeout = setTimeout(this.submitRecipe, 1000, recipe)
    },
    submitRecipe: function(recipe) {
      console.log("submitRecipe")
      db.collection('users').doc(this.activeUserID)
        .collection('recipes').doc(this.recipeID)
        .update(recipe)
    },
    getIngredientsSnapshot: function() {
      const recipeRef = db.collection('users').doc(this.activeUserID)
      .collection('recipes').doc(this.recipeID);
      //this.unsubscribe = recipeRef.onSnapshot(snapshot => {
      recipeRef.get().then(snapshot => {
          const data = snapshot.data()
          if(data && data.ingredients) {
            this.initRecipeIngredients(data.ingredients)
          }
        })
    },
    initRecipeIngredients:function(ingredients) {
      this.recipeIngredients = []
      for (let i = 0; i < ingredients.length; i++) {
        const ingredient = ingredients[i];
        ingredient.id = i;
        ingredient.selected = false;
        ingredient.calculatedNumber = this.round(this.eaterPersonRatio * ingredient.aantal,1)
        this.recipeIngredients.push(ingredient);
      }
      this.calculateNutrients()
    },
    setRecipeTags: function(recipeTags) {
      if(!recipeTags || recipeTags.length == 0) {
        this.validateTags = true
        return;
      }
      this.validateTags = false;
      this.selectedTags = []
      for (let i = 0; i < recipeTags.length; i++) {
        const tg = recipeTags[i];
        this.selectedTags.push(tg.name)
      }
      this.$nextTick(()=>{
        this.validateTags = true;
      })
    },
    unsubscribeIngredients: function() {
      if(this.unsubscribe) this.unsubscribe();
    },
    initQuantumData: function(recipe) {
      if(!recipe.eaterPersonRatio) {
        this.numberOfEaters = this.numberOfPersons = recipe.aantal_personen
        this.eaterPersonRatio = 1
      } else {
        this.numberOfPersons = recipe.aantal_personen
        this.eaterPersonRatio = recipe.eaterPersonRatio
        this.numberOfEaters = recipe.eaterPersonRatio * recipe.aantal_personen
      }
    },
    sort() {
      this.list = this.list.sort((a, b) => a.order - b.order);
    },
  },
  mounted: function() {
    if(this.activeUserID === null) {
      this.$router.push("/home");
    }
    var self = this
    setTimeout(()=> {
      if(self.$refs.header) {
        self.headerHeight =  self.$refs.header.scrollHeight
      }
    },1000)
    
    //console.log(this.user, this.activeUserID, this.recipeID)
    if(this.activeUserID) {
      const userRef = db.collection('users').doc(this.activeUserID)
      const ref =  userRef.collection('recipes').doc(this.recipeID);
      ref.get().then((doc) => {
        this.recipe = doc.data();
        this.initQuantumData(this.recipe)
        this.getIngredientsSnapshot()
        this.setRecipeTags(this.recipe.tags)

        const imageName = this.recipe.image ? this.recipe.image : this.getDefaultImageName()
        this.loadImage(this.getImageRef(imageName))


        userRef.collection("shoppingLists").doc("active").get().then( shpLstDoc => {
            const data = shpLstDoc.data()
            this.shoppingListRecipes = (data && data.recipes) ? data.recipes : []
            this.shoppingList = (data && data.items) ? data.items : []
          })
      })
      .catch((error) => {
          console.error("Error retrieving recipe: ", error);
      });
    }

    
  },
};
</script>
<style>
.headerFade-enter-active {
  transition: opacity 1s;
}
.headerFade-enter  /* .headerFade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.1;
  background: #55994B;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
.custom-file-input ~ .custom-file-label::after {
  content: 'Browsse';
}

</style>
