import db from "../db.js";
export const shoppingListMixin = {
  data() {
    return {
      shoppingList: [],
      shoppingListRecipes: [],
    };
  },
  methods: {
    /**
     * 1) Loops through this ingredients
     * 2) Multiplies aantal with the eaterPersonRatio
     * 3) Loops through the shoppingList to match ingredient and unit.
     * 4) If found adds up aantal of units, if not found adds ingredient to the shoppingList
     *
     * @param {*} recipe
     * for recipe[ingredients] => i
     *   i.aantal = r.eaterPersonRatio * i.aantal
     *   for [this.shoppingList] => sli
     *     if(i.ingredient == sli.ingredient && i.eenheid == sli.eenheid)
     *       sli.aantal += i.aantal
     *       found = true
     *   if(!found)
     *     this.shoppingList.push(clone(i))
     */
    addRecipe: function(recipe) {
      const rcp = Object.assign({}, recipe);
      rcp.recipeInstanceID = Date.now().toString(36);
      this.shoppingListRecipes.push(rcp);
      if (!recipe.eaterPersonRatio) {
        recipe.eaterPersonRatio = 1;
      }
      for (let j = 0; j < recipe.ingredients.length; j++) {
        const rcpItem = Object.assign({}, recipe.ingredients[j]);
        rcpItem.aantal *= recipe.eaterPersonRatio;
        var found = false;
        for (let i = 0; i < this.shoppingList.length; i++) {
          const shpLstItem = this.shoppingList[i];
          if (
            rcpItem.ingredient.id == shpLstItem.ingredient.id &&
            rcpItem.eenheid.id == shpLstItem.eenheid.id
          ) {
            found = true;
            shpLstItem.aantal += rcpItem.aantal;
            shpLstItem.selected = false;
            shpLstItem.recipeIDs.push({
              recipeInstanceID: rcp.recipeInstanceID,
              aantal: rcpItem.aantal,
            });
          }
        }
        if (!found) {
          rcpItem.recipeIDs = [
            { recipeInstanceID: rcp.recipeInstanceID, aantal: rcpItem.aantal },
          ];
          this.shoppingList.push(rcpItem);
        }
      }
      const shoppingListRef = db
        .collection("users")
        .doc(this.activeUserID)
        .collection("shoppingLists")
        .doc("active");
      shoppingListRef.update({
        items: this.shoppingList,
        recipes: this.shoppingListRecipes,
      });
    },
    removeRecipe: function(recipe) {
      for (let i = 0; i < this.shoppingListRecipes.length; i++) {
        const rcp = this.shoppingListRecipes[i];
        if (recipe.recipeInstanceID == rcp.recipeInstanceID) {
          this.shoppingListRecipes.splice(i, 1);
          break;
        }
      }
      if (!recipe.eaterPersonRatio) {
        recipe.eaterPersonRatio = 1;
      }
      var keep = [];
      for (let i = 0; i < this.shoppingList.length; i++) {
        const shpLstItem = this.shoppingList[i];
        if (shpLstItem.recipeIDs) {
          for (let j = shpLstItem.recipeIDs.length - 1; j >= 0; j--) {
            const rcpShpLstID = shpLstItem.recipeIDs[j];
            if (rcpShpLstID.recipeInstanceID == recipe.recipeInstanceID) {
              shpLstItem.aantal -= rcpShpLstID.aantal;
              shpLstItem.recipeIDs.splice(j, 1);
            }
          }
        }
        if (!shpLstItem.recipeIDs || shpLstItem.recipeIDs.length > 0) {
          keep.push(shpLstItem);
        }
      }
      this.shoppingList = keep;
      const shoppingListRef = db
        .collection("users")
        .doc(this.activeUserID)
        .collection("shoppingLists")
        .doc("active");
      shoppingListRef.update({
        items: this.shoppingList,
        recipes: this.shoppingListRecipes,
      });
    },
  },
};
