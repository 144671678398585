<template>
   <b-modal id="modalEditOperation" modal-class="modal-fullscreen" title="Pas bewerking aan" hide-backdrop
      size="md"  
      @show="initModal"
      @ok="handleOperationModalOk">
      <b-form ref="operationForm" @submit.stop.prevent="modalEditOperationSubmit()" >
         <b-form-group label="naam:" :invalid-feedback="naamInvalidInfo" :state="naamState">
          <b-form-input ref="inputNaam" class="col-6 px-1 px-sm-2" required :state="naamState"
            v-model="operationName" />
        </b-form-group>
      </b-form>
   </b-modal>
</template>

<script>
import db from "../db.js"

export default {
   name:"modal-edit-operation",
   props: ["userID", "operations" , "selectedOperation"],
   data: function() {
      return {
         recipes: [],
         unsubscribe: null,
         oldName: null,
         naamState: null,
         naamInvalidInfo: "Deze naam kan niet toegevoegd worden",
         emptyOperation: {id:null ,naam: ""},
         operationName: null,
      };
   },
   methods: {
      initModal: function() {
         if(this.selectedOperation) {
            this.operationName = this.oldName = this.selectedOperation.naam
         }
         this.focusInput()
      },
      focusInput: function() {  
         setTimeout(() => {
            this.$refs.inputNaam.select()
         },500)

      },
      handleOperationModalOk: function(bvModalEvent) {
         bvModalEvent.preventDefault();
         this.modalEditOperationSubmit();
      },
      handleCancel: function() {
         this.selectedOperation = this.emptyOperation
      },
      /**
       * 1) check of het veld naam van operation is ingevuld
       * 2) check of operation niet al bestaat
       * 3) voeg het operation aan de DB toe onder local operations
       * 4) dispatch 'operationCreated' event
       * 5) sluit het venster 
       */
      modalEditOperationSubmit: function() {
         this.naamState = true
         this.$refs.operationForm.checkValidity()
         this.selectedOperation.naam = this.operationName.trim()
         if(this.selectedOperation.naam == "") {
            this.naamState = false;
            this.naamInvalidInfo = "Geef een naam op"
         } else {
            for (let i = 0; i < this.operations.length; i++) {
               const oprtn = this.operations[i];
               if(oprtn.text == this.selectedOperation.naam) {
                  this.naamInvalidInfo = "Operation bestaat al"
                  this.naamState = false;
                  break;
               }
            }
         }
         if(!this.naamState ) {
            return
         }
         const oprtnRef = db.collection("users").doc(this.userID).collection("operations").doc(this.selectedOperation.id)
         oprtnRef.update(this.selectedOperation).then(()=>{
               let selectedOperationInstance = false
               //find exact new object in operations to make matching possible in dropdown of ModalRecipeOperation
               for (let i = 0; i < this.operations.length; i++) {
                  const oprtn = this.operations[i];
                  if(oprtn.value.naam == this.selectedOperation.naam) {
                     selectedOperationInstance = oprtn.value
                     break;
                  }
               }
               if( selectedOperationInstance) {
                  //no need to add to operations because the onSnapshot in App.js will add it to the clients operations array
                  this.$emit("operationUpdated", selectedOperationInstance)
               }
               
               this.$nextTick(()=> {
                  this.$bvModal.hide('modalEditOperation')
               })

         })
         .catch(error => {
            console.log(error)
         })

      }
   },
}
</script>
