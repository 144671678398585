<template>
   <b-modal id="modalSelectRecipe" scrollable modal-class="modal-fullscreen" title="Selecteer recepten" hide-backdrop
      size="md"  
      @ok="addRecipes()">
      <b-tabs v-model="selectedTab" content-class="mt-3">
        <b-tab title="Categorie" >
          <b-form-group label="Selectie methode" v-slot="{ ariaDescribedby }">
            <b-form-radio v-model="selectedRadio" :aria-describedby="ariaDescribedby" name="some-radios" value="inclusive">Recepten die minstens aan één van de geselecteerde categorieën voldoen</b-form-radio>
            <b-form-radio v-model="selectedRadio" :aria-describedby="ariaDescribedby" name="some-radios" value="exclusive">Uitsluitend recepten die aan alle geselecteerde categorieën voldoen</b-form-radio>
          </b-form-group>
          <b-form-group
            label="Categorieën"
            v-slot="{ ariaTags }">
            <b-form-checkbox-group
              v-model="selectedTags"
              :options="tags"
              :aria-describedby="ariaTags"
              name="selectTags"
              stacked
            ></b-form-checkbox-group>
          </b-form-group>
        </b-tab>
        <b-tab title="Specifiek" >
            <b-container class="px-0">
              <b-row class="m-0">
                <b-col class="mr-3 px-0">
                  <b-input-group size="md">
                    <b-form-input 
                      type="text"
                      placeholder="Zoek recepten"
                      ref="searchInput"
                      />
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <a v-b-toggle.collapse href="#">{{filterVisible ? 'Verberg filter': 'Filter'}}</a>
                  <b-collapse v-model="filterVisible" id="collapse" ref="filterCollapse" >
                      <b-form-checkbox-group v-model="selectedFilterTags" :options="tags" class="mt-2" />
                  </b-collapse>
                </b-col>
              </b-row>
            </b-container>
          <b-list-group class="list-group-flush">
            <b-list-group-item v-for="item in listDataprovider" 
              :key="item.id"
              class="py-0 mb-0">
              <b-row>
                <b-col class="ml-1 mt-1">
                  <b-form-checkbox
                    class="form-check-input text-truncate"
                    :switch="false"
                    size="md"
                    v-model="item.selected"
                    @change="toggleSelectedRecipe(item)">
                    {{ item.naam }}</b-form-checkbox>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </b-tab>
      </b-tabs>
   </b-modal>
</template>
<script>
import db from "../db.js";
export default {
   name:"modal-select-recipe",
   data: function() {
      return {
         recipes: [],
         listDataprovider: [],
         unsubscribe: null,
         selectedTab: 0,
         selectedRadio: 'inclusive',
         selectedTags: [],
         selectedRecipes: [],
         filterVisible: null,
         selectedFilterTags: null,
      };
   },
   props: ["userID", "tags"],
   methods: {
      addRecipes: function() {
        this.selectedRecipes = []
        if(this.selectedTab == 0) {
          if(this.selectedRadio == 'inclusive') {
            this.findInclusiveRecipesForTags()
          } else {
            this.findExclusiveRecipesForTags()
          }
        } else {
          for (let i = 0; i < this.listDataprovider.length; i++) {
            const item = this.listDataprovider[i];
            if(item.selected) {
              this.selectedRecipes.push(this.recipes[i])
              item.selected = false;
            }
          }
          //trigger databinding to refresh list and show clean sheet next time
          this.listDataprovider = [...this.listDataprovider]
        }
        this.$emit("addRecipes", this.selectedRecipes)
      },
    /**
     * Helper function to show number of persons for a recipy in the list
     */
    getAantalPersonen: function(item) {
      var aantal =
        item.aantal_personen *
        (item.eaterPersonRatio ? item.eaterPersonRatio : 1);
      return aantal + " personen";
    },
    findInclusiveRecipesForTags:function() {
      for (let i = 0; i < this.recipes.length; i++) {
        const recipe = this.recipes[i];
        const rcpTags = recipe.tags
        if(rcpTags){
          for (let j = 0; j < rcpTags.length; j++) {
            var found = false
            const tag = rcpTags[j];
            for (let k = 0; k < this.selectedTags.length; k++) {
              const selectedTag = this.selectedTags[k];
              if(tag.id == selectedTag.id) {
                found = true
                this.selectedRecipes.push(recipe)
                break;
              }
            }
            if(found) {
              break;
            }
          }
        } 
      }
    },
    findExclusiveRecipesForTags:function() {
      for (let i = 0; i < this.recipes.length; i++) {
        const recipe = this.recipes[i];
        const rcpTags = recipe.tags
        if(rcpTags){
          let foundAll = true
          for (let k = 0; k < this.selectedTags.length; k++) {
            const selectedTag = this.selectedTags[k];
            for (let j = 0; j < rcpTags.length; j++) {
              var found = false
              const tag = rcpTags[j];
              if(tag.id == selectedTag.id) {
                found = true
                break;
              }
            }
            if(!found) {
              foundAll = false
              break;
            }
          }
          if(foundAll) {
            this.selectedRecipes.push(recipe)
          }
        } 
      }
    },
    toggleSelectedRecipe: function(item) {
      console.log(item.naam)
      //item.selected = !item.selected
      console.log("selected: " + item.selected)
    }
   },

  /**
   * 
   * DB.user.recipes.onSnapshot => snapshot 
   *  this.recipes = []
   *  for[snapshot] => doc
   *    recipe = doc.data()
   *    recipe.id = data.id
   *    this.recipes.push(recipe)
   *  createShoppingList()
   */
  mounted: function() {
      this.listDataprovider = []
      this.unsubscribe = db.collection("recipes").onSnapshot((snapshot) => {
         var snapData = [];
         snapshot.forEach((doc) => {
            var recipe = doc.data();
            recipe.id = doc.id;
            snapData.push(recipe);
         });

        //firebase .orderBy is not sorting yet case insensitive
        this.recipes = snapData.sort((a, b) => {
          if(!(a.naam && b.naam) ) return 0;
          if(a.naam.toLowerCase() < b.naam.toLowerCase()){
            return -1;
          } else {
            return 1;
          }
        });
        for (let i = 0; i < this.recipes.length; i++) {
          const recipe = this.recipes[i];
          this.listDataprovider.push({id:recipe.id, naam: recipe.naam, selected: false})
        }
        
      });
  },
  unmounted: function() {
    this.unsubscribe();
  },
}
</script>

