<template>
    <b-container id="adminMigrateRecipes" class=" mt-0">
    <b-row>
      <b-col>
        <b-form class="mt-0 mb-3" @submit.prevent="addTableToFireStore">
          <b-card title="Databewerking" class="bg-light">
            <div class="card-body">
              <div class="form-group">
                <label class="form-control-label" for="ownername">Tabelnaam</label>
                <input
                  class="form-control"
                  type="text"
                  id="ownername"
                  placeholder="recept/recept_ingredient/ingredient/bewerking/eenheid"
                  v-model="tableName"
                />
              </div>
              <small class="form-text text-muted mb-3" id="uitleg1">Tabel wordt aangemaakt of moet leeg zijn in FireStore.</small>
              <fieldset class="form-group">
                <div class="form-group">
                  <label class="form-control-label" for="jsonObjectStr"
                    >Paste json object</label
                  >
                  <textarea
                    class="form-control"
                    v-model="tableObj"
                    id="jsonObjectStr"
                    rows="3"
                  ></textarea>
                </div>
              </fieldset>
              <button class="btn btn-secondary " type="submit">Submit</button>
            </div>
          </b-card>
        </b-form>
      </b-col>
    </b-row>

    <b-card class="mb-3 card bg-light">
      <b-card-body class="card-body">
        <b-form>
        <b-form-group >
          <b-button class="btn btn-primary m-2" @click="addVegetarianTag" >
            Voeg 'vegetarian' tag toe
          </b-button>
          <small class="form-text text-muted ml-2" id="uitleg1">Vind vegetarische recepten en voeg een vegetarische tag toe.</small>
        </b-form-group>
        <b-form-group >
          <b-button v-b-modal.modalAddIngredient class="btn btn-primary m-2" @click="addNewIngredient" >
            Voeg een nieuw ingrediënt toe
          </b-button>
          <b-button v-b-modal.modalUpdateIngredient class="btn-secondary m-2" @click="addNewIngredient" >
            Pas een ingredient aan
          </b-button>
          <small class="form-text text-muted ml-2" id="uitleg1">Voe een nieuw ingrediënt toe aan de lijst.</small>
        </b-form-group>
        <b-form-group >
          <b-button class="btn btn-secondary m-2" @click="migrateReceptToUsers()" >
            Migreer recept tabel naar users
          </b-button>
          <small class="form-text text-muted ml-2" id="uitleg1">Hiermee wordt de recept tabel naar user gemigreerd.</small>
        </b-form-group>
        <b-form-group >
          <div class="col-12 alert alert-danger px-3" v-if="error">
            {{ error }}
          </div>
          <button class="btn btn-secondary m-2" @click="addIngredientsToRecepies()">
            Voeg ingredient toe aan recept deel 1
          </button>
          <button class="btn btn-secondary m-2" @click="sampleIngredientDetails()">
            Verzamel collection id's deel 2
          </button>
          <button class="btn btn-secondary m-2" @click="addIngredientDetailToIngredient()">
            Voeg ingredient-detail toe aan ingredient deel 3
          </button>
          <button class="btn btn-secondary m-2" @click="addDetailToIngredient('eenheid')">
            Voeg eenheid-detail toe aan ingredient deel 4
          </button>
          <button class="btn btn-secondary m-2" @click="addDetailToIngredient('bewerking')">
            Voeg bewerking-detail toe aan ingredient deel 5
          </button>
          <small class="form-text text-muted ml-2" id="uitleg1"> Hiermee worden ingredienten aan recepten gekoppeld van de 'Boodschappen' spreadsheet. 
              Deel 1 kan alleen uitgevoerd worden als de tabel recept_ingredient aanwezig is. Deel 2 maakt een opzoek-array met daarin de collection id's 
              van recept- en ingredienten collection id's. Deel 3, 4 en 5 voegen subjectievelijk het ingredient, detail en bewerking toe aan de recept tabel 
              en kan alleen uitgevoerd worden als met deel 2 de koppeling-id's zijn verzameld.</small>
        </b-form-group>
        <b-form-group >
          <button class="btn btn-secondary m-2" @click="removeIngredientsFromRecepies()" >
            Verwijder ingredienten van recepten
          </button>
          <small class="form-text text-muted ml-2" id="uitleg1"> Hiermee worden alle ingredienten van recept verwijderd en kan overnieuw begonnen worden. Voorwaarde is dat recept_ingredient geimporteerd is en ook ingredient,eenheid en bewerking aanwezig is. </small>
        </b-form-group>
        </b-form>
      </b-card-body>
    </b-card>
    <modal-add-ingredient />
    <modal-update-ingredient :ingredients="ingredients" />
  </b-container>
</template>

<script>
import db from "../db.js";
import ModalAddIngredient from "@/components/ModalAddIngredient"
import ModalUpdateIngredient from "@/components/ModalUpdateIngredient"
// import Firebase from "firebase"
export default {
  name: "admine-migrate-recipes",
  props: ["user", "ingredients"],
  data: function() {
    return {
      error: "",
      tableName: "",
      tableObj: "",
      updateIngredientArr: [],
    };
  },
  components: {ModalAddIngredient, ModalUpdateIngredient},
  methods: {
    addVegetarianTag: function() {
      var ref = db.collection("users").doc(this.user.uid);
      ref.collection("recipes").get().then((rcpCol) => {
        rcpCol.forEach( rcpDoc => {
          var recipeId = rcpDoc.id
          var recipeRef = ref.collection("recipes").doc(recipeId)
          ref.collection("recipes").doc(rcpDoc.id)
          .collection("ingredients").get().then(ingrCol => {
            var vegetarian = true
            ingrCol.forEach(ingrDoc => {
              var ingredient = ingrDoc.data().ingredient;
              if(ingredient && (ingredient.categorie == "vlees" || ingredient.categorie == "vis")){
                vegetarian = false
              }
            })
            if(vegetarian) {
              recipeRef.update({tags: ["vegetarian"]})
            } else {
              recipeRef.update({tags: ["non-vegetarian"]})
            }
          })
        })
      })
    },
    addNewIngredient: function() {
      console.log("addUpdateIngredient")
    },
    migrateReceptToUsers: function() {
      var userRef = db.collection('users').doc('rTL1tdS5LRSfJ36dlGwauzGp4IV2').collection('recipes')
      db.collection('recept').get()
        .then( rcpCol => {
          rcpCol.forEach( rcpDoc => {
            console.log(rcpDoc.id + ": " + rcpDoc.data().naam + " toegevoegd aan user")
            userRef.doc(rcpDoc.id).set(rcpDoc.data()).then(
              db.collection('recept').doc(rcpDoc.id).collection('ingredienten').get()
                .then( ingrCol => {
                  ingrCol.forEach( ingrDoc => {
                    userRef.doc(rcpDoc.id).collection('ingredients').doc(ingrDoc.id).set(ingrDoc.data())
                  })
                })
              
            )
          })
        })
    },
    addTableToFireStore: function() {
      const objecten = JSON.parse(this.tableObj);
      for (const obj in objecten) {
        db.collection(this.tableName).add(objecten[obj]);
        console.log(objecten[obj]);
      }
    },
    addIngredientsToRecepies: function() {
      this.error = "";
      console.log("addIngredientsToRecepies");
      db.collection("recept_ingredient")
        .get()
        .then((col) => {
          if (col) {
            const snapData = [];
            col.forEach((doc) => {
              snapData.push({
                id: doc.id,
                data: doc.data(),
              });
            });
            for (var i = 0; i < snapData.length; i++) {
              const rec_ingr = snapData[i].data;

              var ingRef = db.collection("recept");
              ingRef
                .where("id", "==", snapData[i].data.recept_id)
                .get()
                .then((snapshot) => {
                  snapshot.forEach((doc) => {
                    console.log(doc.id, " => ", doc.data());
                    db.collection("recept")
                      .doc(doc.id)
                      .collection("ingredienten")
                      .add(rec_ingr);
                  });
                })
                .catch((error) => {
                  console.log("Error getting documents: ", error);
                });
            }
            console.log("klaar");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    },

    sampleIngredientDetails: function() {
      this.error = "";
      this.updateIngredientArr = [];
      console.log("sampleIngredientDetails");
      db.collection("recept")
        .get()
        .then((recCols) => {
          recCols.forEach((recDoc) => {
            var recept_id = recDoc.id;
            var ref = db.collection("recept").doc(recDoc.id).collection("ingredienten");

            ref.get().then((ingCols) => {
              ingCols.forEach((ingDoc) => {
                var rec_ing = ingDoc.data();
                rec_ing["recept_id"] = recept_id;
                rec_ing["recept_ingredient_id"] = ingDoc.id;
                var query = db
                  .collection("ingredient")
                  .where("id", "==", rec_ing.ingredient_id);
                query.get().then((snapshot) => {
                  snapshot.forEach((doc) => {
                    rec_ing["ingredient"] = doc.data();
                    rec_ing.ingredient["ingredient_id"] = doc.id;
                    this.updateIngredientArr.push(rec_ing);
                    // console.log(rec_ing)
                  });
                });
              });
            });
          });
        });
    },

    addIngredientDetailToIngredient: function() {
      console.log("addIngredientDetailToIngredient");
      if (this.updateIngredientArr.length == 0) {
        this.error = "updateIngredientArr is leeg. Voer eerst deel 2 uit";
        return;
      }
      this.error = "";
      for (var i = 0; i < this.updateIngredientArr.length; i++) {
        var ingDet = this.updateIngredientArr[i];

        console.log( i + ": " + ingDet.recept_id + ": " + ingDet.recept_ingredient_id + ": " + ingDet.ingredient.ingredient_id);
        var ingr = ingDet.ingredient
        var ingredClean =  {
            aantal: ingDet.aantal,
            aanvulling: ingDet.aanvulling,
            bewerking_id: ingDet.bewerking_id,
            eenheid_id: ingDet.eenheid_id,
            ingredient: {
                categorie: ingr.categorie,
                id: ingr.ingredient_id,
                meervoud: ingr.meervoud,
                naam: ingr.naam
            }
        }
        db.collection("recept")
          .doc(ingDet.recept_id)
          .collection("ingredienten")
          .doc(ingDet.recept_ingredient_id)
          .set(ingredClean);
      }
    },
    addDetailToIngredient: function(detailName) {
      var detailHash = {}
      db.collection(detailName).get()
        .then( dtlCol => {
          dtlCol.forEach(dtlDoc => {
            const id = dtlDoc.data().id
            const frbsId = dtlDoc.id
            detailHash[id] = dtlDoc.data()
            detailHash[id].id = frbsId
          })
        })
      var refRcpCol = db.collection('recept')
      refRcpCol.get()
        .then( rcptCol => {
          rcptCol.forEach(rcptDoc => {
            const rcptId = rcptDoc.id
            var refIngrCol = refRcpCol.doc(rcptId).collection('ingredienten')
            refIngrCol.get()
              .then(ingrCol => {
                ingrCol.forEach( ingrDoc => {
                  var ingrData = ingrDoc.data()
                  const refIngrDoc = refIngrCol.doc(ingrDoc.id)
                  if(ingrData[detailName + "_id"] && ingrData[detailName + "_id"] != ""){
                    ingrData[detailName] = detailHash[ingrData[detailName + "_id"]]
                  }
                  //fix name convention
                  if(ingrData.ingredient.ingredient_id) {
                    ingrData.ingredient.id = ingrData.ingredient.ingredient_id
                    delete ingrData.ingredient.ingredient_id
                  }
                  delete ingrData[detailName + "_id"] 
                  console.log(ingrData)
                  refIngrDoc.set(ingrData)
                })
              })
          })
        })
    },
    removeIngredientsFromRecepies: function() {
      var remove = false
      if(!remove) {
        this.error = "Dit zou ik niet zomaar zonder overleg doen"
        return;
      } else {
          db.collection("recept")
          .get()
          .then((col) => {
            col.forEach((doc) => {
              var ref = db
                .collection("recept")
                .doc(doc.id)
                .collection("ingredienten");
              ref.get().then((ings) => {
                ings.forEach((ing) => {
                  ref.doc(ing.id).delete();
                });
              });
            });
          })
          .catch((error) => {
            console.log("Error:", error);
          });
        }
    },
  },
};
</script>

