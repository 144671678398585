<template>
   <b-card :title="title" class="card bg-light">
      <b-card-body class="py-2 px-3">
         <b-container class="px-0">
            <b-link v-if="selectedRecipes.length == 0" @click="navigateTo('/recipe-list')" href="#" >Voeg recepten toe</b-link>
            <b-list-group class="list-group-flush">
               <b-list-group-item v-for="item in recipes" 
               :key="item.__key"
               class="d-flex justify-content-between align-items-top py-1 px-1"><b-link  @click="navigateToRecipe(item.id)">
                  {{ item.naam }} ({{item.aantal_personen * item.eaterPersonRatio}} prs.)
                  </b-link><div><b-button v-if="deleteRecipeActive" @click="$emit('removeRecipe', item)" size="sm" variant="secundary" class="mb-2"><b-icon icon="x" scale="1.5" style="color: #666666;"/></b-button></div>
               </b-list-group-item>
            </b-list-group>
            <div class="mt-3 ml-0">
               <b-link  v-if="selectedRecipes.length > 0"
                     :to="toLink" >{{toTitle}}</b-link></div>
         </b-container>
      </b-card-body>
   </b-card>
</template>
<script>

export default {
   name: "card-selected-recipes",
   data: function(){
      return {
         counter: 0,
         recipes: [],
      }
   },
   props: ["selectedRecipes", "title", "toTitle", "toLink", "deleteRecipeActive"],
   watch: {
      selectedRecipes: function(value) {
         if(value !== null) {
            this.initCard()
         }
      }
   },
   methods: {
      initCard:function () {
         if(this.selectedRecipes ) {
            var rcps = []
            for (let i = 0; i < this.selectedRecipes.length; i++) {
               const item = this.selectedRecipes[i];
               var temp = {...item}
               temp.__key = i
               rcps.push(temp)
            }
            this.recipes = rcps
         }
      },
      getKey:function() {
         return String(Math.floor(Math.random() * 1000))
      },
      navigateToRecipe(recipeId) {
         this.$router.push('/recipe-detail/'+ recipeId);
      },
      navigateTo: function(target) {
         if(this.$router.history.current.name != "recipe-list") {
            this.$router.push(target);
         }else {
            this.scrollToTop()
         }
      },
      scrollToTop: function() {
         var position = document.documentElement.scrollTop;
         var step = position / 15;
         var int = setInterval(function() {
            position -= step;
            window.scrollTo(0, position);
            if(position < 0) position = 0;
            if (position <= 0) clearInterval(int);
         }, 20); 
      }
   },
   mounted() {
      //nextTick is too early for selectedRecipes to be initiated
      setTimeout(()=> {
         this.initCard()
      }, 300)
   }
}
</script>
