<template>
  <b-container @switchUser="switchUser" class="p-0 ">
    <Navigation 
      :user="user"
      :roles="roles"
      :invitationCount="invitationCount" 
      :shoppingLists="shoppingLists"
      :activeShoppingListName = "activeShoppingListName"
      :isAdmin="isAdmin"
      @logout="logout" 
      @login="login" 
      @openModalInviteUser="openModalInviteUser"
      @openModalAcceptInvitations="openModalAcceptInvitations" 
      @switchRecipeShoppingList="switchRecipeShoppingList"/>
      <transition
        :name="transitionName"
        mode="out-in"
      >
        <router-view 
          class="container"
          :user="user"
          :userID="userID"
          :activeUserID="activeUserID"
          :activeShoppingListIDs="activeShoppingListIDs"
          :activeShoppingListName = "activeShoppingListName"
          :roles="roles"
          :error="error"
          :units="units"
          :ingredients="ingredients"
          :nevoIngredients="nevoIngredients"
          :operations="operations"
          :tags="tags"
          @openModalInviteUser="openModalInviteUser"
          @openModalAcceptInvitations="openModalAcceptInvitations" 
          @showAdminUI="showAdminUI"
          @logout="logout"
          @initUser="initUser"
          @showToast="showToast"/>
      </transition>
    <modal-invite-user @showToast="showToast" :userID="userID" :displayName="userDisplayName" />
    <modal-accept-invitations :invitations="invitations" :userID="userID" />
  </b-container>
</template>

<script>
import Navigation from './components/Navigation.vue'
import firebase from "firebase"
import "firebase/functions" 
import ModalAcceptInvitations from './components/ModalAcceptInvitations.vue'
import ModalInviteUser from './components/ModalInviteUser.vue'

import db from "./db.js"
import { timeout } from 'q';
const DEFAULT_TRANSITION = 'fade';
export default {
  name: 'App',
  data: function () {
    return {
      user: null,
      userID: null,
      userDisplayName: null,
      activeUserID: null,
      activeShoppingListIDs: null,
      activeShoppingListName: null, 
      shoppingLists: [],
      roles: [],
      error: null,
      ingredients: [],
      globalIngredients: [],
      nevoIngredients: [],
      localIngredients: [],
      operations: [],
      globalOperations: [],
      localOperations: [],
      units: [],
      tags: [],
      localTags: [],
      invitations: [],
      invitationCount: 0,
      transitionName: DEFAULT_TRANSITION,
      activeUserIdUpdated: false,
      isAdmin: false,
      userInitialized: false,
    };
  },
  components: {
    Navigation, ModalAcceptInvitations, ModalInviteUser
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName;

      if (transitionName === 'slide') {
        const toDepth = to.path.split('/').length;
        const fromDepth = from.path.split('/').length;
        transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
      }

      this.transitionName = transitionName || DEFAULT_TRANSITION;

      next();
    });
  },
  methods: {    
    logout: function () {
      firebase.auth()
        .signOut()
        .then(() => {
          this.user = null;
          this.userID = null;
          this.userDisplayName = null;
          this.activeUserID = null;
          this.activeShoppingListIDs= null;
          this.activeShoppingListName= null; 
          this.shoppingLists= [];
          this.roles = [];
          this.error = null;
          this.ingredients= [];
          this.globalIngredients= [];
          this.localIngredients= [];
          this.operations= [];
          this.globalOperations= [];
          this.localOperations= [];
          this.units= [];
          this.tags= [];
          this.localTags= [];
          this.invitations = [];
          this.invitationCount = 0;
          this.activeUserIdUpdated = false;
          this.isAdmin = false
          this.userInitialized = false;


          this.$router.push("/login");
        });
    },
    showAdminUI: function(admin) {
      this.isAdmin = admin
      if(admin) {
        this.roles = ["admin"]
      } else {
        this.roles = []
      }
    },
    login: function() {
      console.log("login user")
    },
    switchUser: function(event) {
      this.activeUserID = event.uid;
      this.activeShoppingListName = event.displayName
    },
    /**
     * example:
     * var toastObj = 
     * {
     *    title: `Waarschuwing`,
     *    toaster: 'b-toaster-bottom-left',
     *    variant: 'warning',
     *    solid: true,
     *    appendToast: false
     *  }
     * showToast([message, toastObj])
     */
    showToast: function( event ) {
      const message = event[0]; 
      const toastObj = event[1];
      this.$bvToast.toast(message, toastObj)
    },
    getIngredientData: function(activeUserID) {
      if(!this.nevoIngredients || this.nevoIngredients.length == 0) {
        db.collection("nevo-ingr").get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                //console.log(doc.id, " => ", doc.data());
                var ingr = doc.data()
                ingr.id = doc.id
                this.nevoIngredients.push(ingr)
            });
        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
        });
      }
      var ingrHash = {}
      db.collection("ingredient").onSnapshot((snapshot) => {
        this.globalIngredients = []
        snapshot.forEach((doc) => {
          var ingr = doc.data();
          ingr.id = doc.id;
          this.globalIngredients.push({value:ingr, local:false, text:ingr.naam});
          ingrHash[ingr.naam] = true
        }) 
        this.createIngredients()
      });
      db.collection("users").doc(activeUserID).collection('ingredients').onSnapshot(localSnapshot => {
        this.localIngredients = []
        localSnapshot.forEach(doc => {
          var localIngr = doc.data();          
          localIngr.id = doc.id;
          if(ingrHash[localIngr.naam]) {
            //filter double ingredients from local store
            db.collection("users").doc(activeUserID).collection('ingredients').doc(localIngr.id).delete()
          } else {
            this.localIngredients.push({value:localIngr, local:true, text:localIngr.naam})
          } 
        })
        this.createIngredients()
      })
    },
    createIngredients:function() {
      this.ingredients = this.globalIngredients.concat(this.localIngredients)
      //firebase .orderBy is not sorting yet case insensitive
      this.ingredients = this.ingredients.sort((a, b) => {
        if(!(a.text && b.text)) return 1;
        if(a.text.toLowerCase() < b.text.toLowerCase()){
          return -1;
        } else {
          return 1;
        }
      })
    },
    getOperationData: function(activeUserID) {
      var ingrHash = {}
      db.collection("bewerking").onSnapshot((snapshot) => {
        this.globalOperations = []
        snapshot.forEach((doc) => {
          var ingr = doc.data();
          ingr.id = doc.id;
          this.globalOperations.push({value:ingr, local:false, text:ingr.naam});
          ingrHash[ingr.naam] = true
        }) 
        this.createOperations()
      });
      db.collection("users").doc(activeUserID).collection('operations').onSnapshot(localSnapshot => {
        this.localOperations = []
        localSnapshot.forEach(doc => {
          var localIngr = doc.data();          
          localIngr.id = doc.id;
          if(ingrHash[localIngr.naam]) {
            //filter double operations from local store
            db.collection("users").doc(activeUserID).collection('operations').doc(localIngr.id).delete()
          } else {
            this.localOperations.push({value:localIngr, local:true, text:localIngr.naam})
          } 
        })
        this.createOperations()
      })
    },
    createOperations:function() {
      this.operations = this.globalOperations.concat(this.localOperations)
      //firebase .orderBy is not sorting yet case insensitive
      this.operations = this.operations.sort((a, b) => {
        if(!(a.text && b.text)) return 1;
        if(a.text.toLowerCase() < b.text.toLowerCase()){
          return -1;
        } else {
          return 1;
        }
      })
    },
    getTagData: function(activeUserID) {
      var tgHash = {}
      db.collection("tags").onSnapshot((snapshot) => {
        this.globalTags = []
        snapshot.forEach((doc) => {
          var tg = doc.data();
          tg.id = doc.id;
          this.globalTags.push({value:tg, local:false, text:tg.name});
          tgHash[tg.name] = true
        }) 
        this.createTags()
      });
      if(db.collection("users").doc(activeUserID).collection('tags').exists) {
        db.collection("users").doc(activeUserID).collection('tags').onSnapshot(localSnapshot => {
          this.localTags = []
          localSnapshot.forEach(doc => {
            var localTg = doc.data();          
            localTg.id = doc.id;
            if(tgHash[localTg.name]) {
              //filter double tags from local store
              db.collection("users").doc(activeUserID).collection('tags').doc(localTg.id).delete()
            } else {
              this.localTags.push({value:localTg, local:true, text:localTg.name})
            } 
          })
          this.createTags()
        })
      }
    },
    createTags:function() {
      this.tags = this.globalTags.concat(this.localTags)
      //firebase .orderBy is not sorting yet case insensitive
      this.tags = this.tags.sort((a, b) => {
        if(!(a.text && b.text)) return 1;
        if(a.text.toLowerCase() < b.text.toLowerCase()){
          return -1;
        } else {
          return 1;
        }
      })
    },
    getMasterData: function(name, arrName, nameTextField='text') {
      this[arrName] = []
      db.collection(name)
      .onSnapshot((col) => {
        col.forEach((doc) => {
          var item = doc.data();
          item.id = doc.id;
          this[arrName].push({value:item, text:item[nameTextField]});
        });
        this[arrName] = this[arrName].sort((a, b) => {
          if(!(a.text && b.text)) return 1;
          if(a.text.toLowerCase() < b.text.toLowerCase()){
            return -1;
          } else {
            return 1;
          }
        })
      });
    },
    openModalAcceptInvitations: function(event) {
      console.log('@openModalAcceptInvitations: ' + event)
      this.$bvModal.show('modalAcceptInvitations')
    },
    openModalInviteUser: function() {
      console.log('@openModalInviteUser: ' + event)
      this.$bvModal.show('modalInviteUser')
    },
    switchRecipeShoppingList: function(event) {
      var activeUserID = event
      for (let i = 0; i < this.shoppingLists.length; i++) {
        const item = this.shoppingLists[i];
        if(item.uid == event) {
          item.selected = true
          this.activeShoppingListName = item.sharedName
        } else {
          item.selected = false
        }
      }
      this.activeUserID = activeUserID
      //set activeUserID to remember selected shoppinglist
      const userRef = db.collection("users").doc(this.userID) 
      userRef.update({activeUserID: activeUserID}).then( () => {
        this.activeUserIdUpdated = true
        //prevent new complete init of user because activeUserID is updated
        timeout(() => {this.activeUserIdUpdated = false}, 500)
      })
    },
    getRecipeShoppingLists:function(data) {
      let shoppingLists = []
      shoppingLists.push({uid:this.userID, selected: this.userID == this.activeUserID})
      var externalShoppingLists = data.externalShoppingLists
      if(externalShoppingLists) {
        for (let i = 0; i < externalShoppingLists.length; i++) {
          const shpList = externalShoppingLists[i];
          if(shpList.status == "active") {
            shoppingLists.push({uid:shpList.uid, selected: shpList.uid == this.activeUserID})
          }
        }
      }
      return shoppingLists
    },
    getInvitations:function(data) {
      let invitations = []
      let externalShoppingLists = data.externalShoppingLists
      if(externalShoppingLists) {
        for (let i = 0; i < externalShoppingLists.length; i++) {
          const shpList = externalShoppingLists[i];
          if(shpList.status == "pending") {
            invitations.push({uid:shpList.uid, data: shpList, selected: true})
          } 
        }
      }
      return invitations
    },
    fetchSharedNameForShoppingLists: function(data, shoppingLists) {
      let promises = []

      for (let i = 0; i < shoppingLists.length; i++) {
        const shpLst = shoppingLists[i];
        promises.push(db.collection('users').doc(shpLst.uid).get().then(doc => {
          shpLst.sharedName = doc.data().sharedName
          return shpLst
        })
        .catch(error => {
          return error
        }))
      }

      return Promise.all(promises)

    },
    getActiveShoppingListName: function(shoppingLists) {
      console.log(shoppingLists)
      for (let i = 0; i < this.shoppingLists.length; i++) {
        const shpLst = this.shoppingLists[i];
        if(shpLst.selected) {
          return shpLst.sharedName
        }
      }
      return "Not found"
    },
    initUser: function(user) {
      this.user = user
      this.userInitialized = false
      if(user != null && (user.emailVerified || this.isAdmin) ) {
        this.userID = this.activeUserID = user.uid
        this.userDisplayName = user.displayName
        this.getMasterData('eenheid', 'units', 'naam')
        //this.activeUserID = this.userID = user.uid
        const userRef = db.collection("users").doc(this.userID)
        userRef.onSnapshot(snapshot => {
          if(this.activeUserIdUpdated) {
            return;
          } 

          var data = snapshot.data()
          if(!data) {
            //user doesn't exist in firestore but has authorisation
            return
          }

          if(this.$router && !this.userInitialized) {
            if (data.routerPath && data.routerPath != "/login" && data.routerPath != "/register") {
              this.$router.replace(data.routerPath)
            } else if(this.$router.name != "recipe-list") {
              this.$router.replace("/recipe-list")
            }
          }

          this.activeUserID = data.activeUserID ? data.activeUserID : snapshot.id

          this.shoppingLists = this.getRecipeShoppingLists(data)
          this.invitations = this.getInvitations(data)
          this.invitationCount = this.invitations.length;
          
          this.fetchSharedNameForShoppingLists(data, this.shoppingLists).then(shoppingLists => {

            this.activeShoppingListName = this.getActiveShoppingListName(shoppingLists)

            //trigger binding so most recent version of shoppinglists will be visible in menu
            this.shoppingLists = this.shoppingLists.slice()
          })

          this.getIngredientData(this.activeUserID)
          this.getOperationData(this.activeUserID)
          this.getTagData(this.activeUserID)
          this.userInitialized = true
        })
      }
    },
   },
  mounted() {
    // For firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
      apiKey: "AIzaSyDex1savUpkBsGp1T7mN0CSyaRZIx70vr4",
      authDomain: "one-time-shopping2-8d136.firebaseapp.com",
      projectId: "one-time-shopping2-8d136",
      storageBucket: "one-time-shopping2-8d136.appspot.com",
      messagingSenderId: "497346799477",
      appId: "1:497346799477:web:261244b0b87a1c8184153c",
      measurementId: "G-MEF8TEXG4B"
    };
    //to prevent firebase being initialized twice
    if (!firebase.apps.length > 0) {
      firebase.initializeApp(firebaseConfig);
    }
    var runEmulator = true
    if (runEmulator && location.hostname === "localhost") {
      try{
        var store = firebase.firestore();
        store.useEmulator(location.hostname, 8082);
        var auth = firebase.auth();
        auth.useEmulator("http://localhost:9099")
        firebase.functions().useEmulator(location.hostname, 5001);
      }catch(err) {
        console.log("code: " + err.code, err.message)
      }
    }

    firebase.auth().onAuthStateChanged((user) => {
      this.initUser(user)
    });

    this.$root.$on('switchUser', this.switchUser);
  }
}
</script>

<style lang="scss">
  // https://markus.oberlehner.net/blog/vue-router-page-transitions/ 
  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease;
  }
  .fade-enter,
  .fade-leave-active {
    opacity: 0
  }
  .slide-left-enter-active,
  .slide-left-leave-active,
  .slide-right-enter-active,
  .slide-right-leave-active {
    transition-duration: 0.5s;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
    //overflow: hidden;
  }

  .slide-left-enter,
  .slide-right-leave-active {
    opacity: 0;
    transform: translate(2em, 0);
  }

  .slide-left-leave-active,
  .slide-right-enter {
    opacity: 0;
    transform: translate(-2em, 0);
  }
  
  #App {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #000000;
    margin-top: 60px;
  }
  [v-cloak] {
    display: none;
  }
</style>
