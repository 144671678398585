<template>
  <b-container class="px-0" @keydown.enter="enterPressed" >
    <b-input-group>
    <b-input ref="input" v-model="searchQuery" @focus="onInputFocus" @keydown.down="onInputKeyDownDown"
      placeholder="Voer een ingredient in" />
    <template #append>
      <b-button variant="primary" @click="onClearInput" ><b-icon  icon="x-circle" style="color: #FFFFFF;"/></b-button>
    </template>
  </b-input-group>
    <div @mouseup="onSelectMouseUp">
    <b-collapse id="collapse-2" :visible="collapsVisible">
        <b-form-select ref="select" :text-field="textField" :value-field="valueField"
           @keydown.down="onSelectKeyDownDown"
           v-model="selectedValue" @blur="onSelectBlur"
          :options="filteredItems" :select-size="selectSize">
        </b-form-select>
    </b-collapse>
  </div>
  </b-container>
</template>
<script>
export default {
  name: "autocomplete",
  props: ["items", "textField", "valueField"],
  data() {
    return {
      selectedValue: null,
      collapsVisible: false,
      searchQuery: "",
      filteredItems: [],
      selectSize:5,
      preventSelect: false,
      countLog: 0,
    }
  },
  watch: {
    searchQuery: function(value) {
      if(value && value.length >= 1){
        this.filterItems()
        if(this.filteredItems.length > 0)
          this.collapsVisible = true;  
      } else {
        this.collapsVisible = false
      }
    }
  },
  methods: {
    filterItems:function() {
      this.filteredItems = this.items
      if(!this.searchQuery || this.searchQuery == "") {
        return
      } else if( this.searchQuery.length > 0) {
        var searchQueryArr = this.searchQuery.split(" ");
        var firstItem = {}
        firstItem[this.textField] = '-- Selecteer optie --'
        firstItem[this.valueField] = null;
        firstItem.disabled = true;
        const self = this
        this.filteredItems = [firstItem].concat( this.items.filter(item => {
          for(var i = 0; i < searchQueryArr.length; i++) {
            var sQ = searchQueryArr[i]
            if(item[self.textField].toLowerCase().indexOf(sQ.toLowerCase()) == -1) {
              return false
            }
          }
          return true;
        }))
      }
    },
    initFilteredItems:function(){
      console.log("method.initFilteredItems")
      var firstItem = {}
      firstItem[this.textField] = '-- Selecteer een optie --'
      firstItem[this.valueField] = null;
      firstItem.disabled = true;
      this.filteredItems = [firstItem].concat(this.items)
    },
    customLog(obj1, obj2 = null) {
      console.log(++this.countLog + ") ", obj1, obj2)
    },
    onSelectBlur() {
      this.preventSelect = false
      this.customLog("onSelectBlur:preventSelect", this.preventSelect)
    },
    onSelectKeyDownDown() {
      this.preventSelect = true
      this.customLog("onSelectKeyDownDown:preventSelect", this.preventSelect)
    },
    onInputFocus() {
      if(this.searchQuery && this.searchQuery.length > 0) {
        this.collapsVisible = true;
      }
    },
    enterPressed(){
      this.collapsVisible = false
      if(this.selectedValue) {
        setTimeout(()=> {
          this.$emit("itemSelected", this.selectedValue)
          this.customLog("enterPressed:selectedValue", this.selectedValue)
        }, 500)
        
      }
      this.$emit("onEnterPressed")
    },
    onSelectMouseUp(){
      this.collapsVisible = false
      setTimeout(() => {
        if(this.selectedValue && !this.preventSelect) {
          this.$emit("itemSelected", this.selectedValue)
          this.customLog("onSelectMouseUp:selectedValue", this.selectedValue)
        }
      }, 500)
      this.$emit("onMouseUp")
    },
    onClearInput() {
      this.searchQuery = ""
    },
    onInputKeyDownDown() {
      this.$refs.select.focus()
    },
    findItem: function(value) {
      console.log("method.findItem")
      for(var index in this.items) {
        var obj = this.items[index]
        if(obj[this.valueField] === value) {
          return obj;
        }
      }
      return null
    }
  }
}  
</script>