import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Login from "./views/Login.vue";
import PasswordForget from "./views/PasswordForget.vue";
import Register from "./views/Register.vue";
import Admin from "./views/Admin.vue"
import RecipeList from "./views/RecipeList.vue"
import RecipeDetail from "./views/RecipeDetail.vue"
import ShoppingList from "./views/ShoppingList.vue"

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/register",
      name: "register",
      component: Register
    },
    {
      path: "/password-forget",
      name: "password-forget",
      component: PasswordForget
    },
    {
      path: "/recipe-list",
      name: "recipe-list",
      component: RecipeList,
    },
    {
      path: "/shopping-list",
      name: "shopping-list",
      component: ShoppingList,
    },
    {
      path: "/recipe-detail/:recipeID",
      name: "recipe-detail",
      component: RecipeDetail,
      meta: { transitionName: 'slide' },
    },
    {
      path: "/admin",
      name: "admin",
      component: Admin
    },
    {
      path: "*",
      redirect: '/'
    }

  ]
});
