<template>
   <b-modal id="modalAcceptInvitations" modal-class="modal-fullscreen" ref="modalAcceptInvitations" 
      title="Accepteer uitnodiging" hide-backdrop
      size="md"
      @ok="handleInvitationsModalOk">
      <b-list-group >
         <b-list-group-item v-for="item in invitations"
            :key="item.uid"
            class="py-0 mb-0">
            <b-row>
               <b-col class="col-10 col-sm-6 col-md-7 ml-2 mt-2 px-0">
                  <p text>naam: {{item.data.displayName}}</p>
               </b-col>
               <b-col cols="auto" class="ml-auto mt-1 mr-1 px-0 ">
                  <b-form-checkbox v-model="item.selected" size="lg" switch />
               </b-col>
            </b-row>
            <b-row>
               <b-col>
                  <p>{{item.uid}}</p>
               </b-col>
            </b-row>
         </b-list-group-item>
      </b-list-group>
   </b-modal>
</template>
<script>
import db from "../db.js"
export default {
   name:"modal-accept-invitations",
   props: ["userID", "invitations"],
   methods: {
      handleInvitationsModalOk: function(bvModalEvent) {
         bvModalEvent.preventDefault();
         this.acceptInvitations();
      },
      acceptInvitations: function() {
         const self = this
         db.collection('users').doc(self.userID).get().then((doc) => {
            const data = doc.data()
            console.log(data)
            var roles = data.roles
            console.log(roles)
            var extShoppingLists = data.externalShoppingLists
            for (let i = 0; i < self.invitations.length; i++) {
               const invitation = self.invitations[i];
               for (let j = 0; j < extShoppingLists.length; j++) {
                  const shpListItem = extShoppingLists[j];
                  if(shpListItem.uid == invitation.uid){
                     if(invitation.selected ) {
                        extShoppingLists[j] = {uid: invitation.uid, status: "active", displayName: invitation.data.displayName}
                     } else {
                        extShoppingLists[j] = {uid: invitation.uid, status: "declined", displayName: invitation.data.displayName}
                     }
                  }
               }
            }
            db.collection('users').doc(self.userID).update({externalShoppingLists: extShoppingLists}).then(()=> {
               self.$bvModal.hide('modalAcceptInvitations')
            })
         })
      }
   }
}
</script>
