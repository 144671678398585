<template>
   <b-img id="thumb" ref="thumb" class="thumb" v-if="imageName" rounded :alt="alt" />
</template>

<script>
import firebase from "firebase"
export default {
   name: "thumb",
   data: function() {
      return {
         url: null
      };
   },
   props: ['imageName', 'alt'],
   methods: {
      initThumb: function() {
         const imagePath = "images/recipes/" + this.imageName;
         const storageRef = firebase.storage().ref();
         storageRef.child( imagePath ).getDownloadURL().then( imageURL => {
            this.url = imageURL
            const image = this.$refs['thumb']
            image.src = imageURL
         })
      }
   },
   mounted() {
      this.$nextTick( () => {
            this.initThumb()
         }
      )
   }
}
</script>

<style>
.thumb {
   width: 100%;
   height: 100px;
   object-fit: cover;
}
</style>


