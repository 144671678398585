<template>
   <b-modal id="modalRecipeDescription" modal-class="modal-fullscreen" title="Pas beschrijving aan." hide-backdrop
      size="md"  
      @show="initModal"
      @ok="handleIngredientModalOk">
      <b-form ref="ingredientForm" @submit.stop.prevent="modalRecipeDescriptionSubmit()" >
         <b-form-group label="recept omschrijving:" >
          <b-form-textarea 
               ref="textarea"
               class="bg-light border rounded-0 border-primary"
               id="beschrijvingArea"
               v-model="recipe.beschrijving"
               rows="8" />
        </b-form-group>
      </b-form>
   </b-modal>
</template>

<script>
//import db from "../db.js"

export default {
   name:"modal-recipe-description",
   props: ["userID", "recipe"],
   data: function() {
      return {
         oldDescription: null,
      };
   },
   methods: {
      initModal: function() {
         this.oldDescription = this.recipe.beschrijving
         
         //this.selectedIngredient = Object.assign({}, this.selectedIngredient)
         this.focusInput()
      },
      focusInput: function() {  
         setTimeout(() => {
            this.$refs.textarea.focus()
         },500)

      },
      handleIngredientModalOk: function(bvModalEvent) {
         bvModalEvent.preventDefault();
         this.modalRecipeDescriptionSubmit();
      },
      handleCancel: function() {
         this.recipe.beschrijving = this.oldDescription
      },
      /**
       * 1) save description of recipe
       */
      modalRecipeDescriptionSubmit: function() {
         this.$emit("submitRecipe", this.recipe)
         this.$nextTick(()=> {
            this.$bvModal.hide('modalRecipeDescription')
         })
      }
   },
}
</script>

