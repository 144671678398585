<template>
   <b-modal id="modalAddIngredient" 
      modal-class="modal-fullscreen" 
      title="Voeg een ingredient toe!" 
      hide-backdrop
      size="md"  
      @show="focusInput"
      @hidden="cleanupModal"
      @ok="handleIngredientModalOk">
      <b-form ref="ingredientForm" @submit.stop.prevent="modalAddIngredientSubmit()" >
        <b-form-group label="naam:" :invalid-feedback="naamInvalidInfo" :state="naamState">
          <b-form-input ref="inputNaam" class="col-6 px-1 px-sm-2" required :state="naamState"
            v-model="newIngredient.naam" />
        </b-form-group>
        <b-form-group label="meervoud:">
          <b-form-input class="col-6 px-1 px-sm-2" 
            v-model="newIngredient.meervoud" />
        </b-form-group>
        <b-form-group label="categorie:">
          <b-form-input class="col-6 px-1 px-sm-2" 
            v-model="newIngredient.categorie" />
        </b-form-group>
      </b-form>
   </b-modal>
</template>
<script>

import db from "../db.js"

export default {
   name:"modal-add-ingredient",
   props: ["userID", "ingredients"],
   data: function() {
      return {
         recipes: [],
         unsubscribe: null,
         naamState: null,
         naamInvalidInfo: "Deze naam kan niet toegevoegd worden",
         newIngredient: {id:null ,naam: "", meervoud: "", categorie: ""}
      };
   },
   computed: {
      ingredientId: function() {
         return (this.newIngredient.naam
         .toLowerCase()
         .replace(/[^\w ]+/g, '')
         .replace(/ +/g, '-') + "-" + this.now)
      },
      now: function() {
         var date = new Date();
         return (
            String (date.getHours()) + 
            String (date.getMinutes()) + 
            String (date.getSeconds()) 
         ) 
      },
   },
   methods: {
      focusInput: function() {  
         setTimeout(() => {
            this.$refs.inputNaam.focus()
         },500)
      },
      handleIngredientModalOk: function(bvModalEvent) {
         bvModalEvent.preventDefault();
         this.modalAddIngredientSubmit();
      },
      handleCancel: function() {
         this.newIngredient = {id:null ,naam: "", meervoud: "", categorie: ""}
      },
      /**
       * 1) check of het veld naam van ingredient is ingevuld
       * 2) check of ingredient niet al bestaat
       * 3) zet categorie op "diversen" als hij niet ingevoerd is
       * 4) voeg het ingredient aan de DB toe onder local ingredients
       * 5) dispatch 'ingredientCreated' event
       * 6) sluit het venster 
       */
      modalAddIngredientSubmit: function() {
         this.naamState = true
         this.$refs.ingredientForm.checkValidity()
         this.newIngredient.naam = this.newIngredient.naam.trim()
         if(this.newIngredient.naam == "") {
            this.naamState = false;
            this.naamInvalidInfo = "Geef een naam op"
         } else {
            for (let i = 0; i < this.ingredients.length; i++) {
               const ingr = this.ingredients[i];
               if(ingr.text == this.newIngredient.naam) {
                  this.naamInvalidInfo = "Ingredient bestaat al"
                  this.naamState = false;
                  break;
               }
            }
         }
         if(!this.naamState ) {
            return
         }
         this.newIngredient.id = this.ingredientId
         if(this.newIngredient.categorie == "") {
            this.newIngredient.categorie = "diversen"
         }
         const ingrRef = db.collection("users").doc(this.userID).collection("ingredients")
         ingrRef.add(this.newIngredient).then((ingrDoc)=>{
               console.log(ingrDoc)
               //find exact new object in ingredients to make matching possible in dropdown of ModalRecipeIngredient
               for (let i = 0; i < this.ingredients.length; i++) {
                  const ingr = this.ingredients[i];
                  if(ingr.value.naam == this.newIngredient.naam) {
                     this.newIngredient = ingr.value
                     this.newIngredient.id = ingrDoc.id
                     break;
                  }
               }
               //save the id in the newly created ingredient so it can be found back in recipes when it get's updated
               ingrRef.doc(this.newIngredient.id).set(this.newIngredient).then( ()=> {
                     //no need to add to ingredients because the onSnapshot in App.js will add it to the clients ingredients array
                     this.$emit("ingredientCreated", this.newIngredient)
                     //this.newIngredient = {id:null ,naam: "", meervoud: "", categorie: ""}
                     this.$nextTick(()=> {
                        this.$bvModal.hide('modalAddIngredient')
                     })
               })
         })
         .catch(error => {
            console.log(error)
         })
      },
      cleanupModal: function() {
            this.newIngredient = {id:null ,naam: "", meervoud: "", categorie: ""}
      }
   },
}
</script>
