<template>
   <b-container id="adminMain">
      <b-card>
         <b-card-body>
            <b-form class="mb-3" inline>
                  <b-input v-model="uid" type="text" :state="uidValid" placeholder="User id" />
                  <b-form-invalid-feedback :state="uidValid">
                     Your user ID must 28 be characters long.
                  </b-form-invalid-feedback>
                  <b-input v-model="displayName" type="text" :state="displayNameValid" placeholder="Naam" class="ml-2 mr-2" inline/>
                  <b-form-invalid-feedback :state="displayNameValid">
                     Your user ID must at least be 1 characters long.
                  </b-form-invalid-feedback>
                  <b-button class="m-1" @click="switchUser" >Switch</b-button>
            </b-form>
            <b-form>
               <b-form-group autofocus label="Add admin">
                  <b-form-input type="email" v-model="email" placeholder="Enter email"/>
                  <b-button @click="addAdminRole" class="m-2">Submit</b-button>
                  <!-- <b-button @click="showToast('testing toast', 'toast', 'danger')" class="m-2">TestToast</b-button> -->
               </b-form-group>
               <b-form-group label="Recipes">
                  <b-button class="m-2" @click="saveTags" >Save tags</b-button>
                  <b-button class="m-2" @click="cleanRecipes(user)" >Clean ingredients</b-button>
                  <b-button class="m-2" @click="recipesSaveToGlobal" >Save recipes to global</b-button>
               </b-form-group>
            </b-form>
         </b-card-body>
      </b-card>
   </b-container>
</template>

<script>
import firebase from "firebase"
import "firebase/functions"
import db from "../db.js";
export default {
   name:"admin-main",
   props: ["user"],
   data: function() {
      return {
         tags: [
               {id:"appetizer", name:"voorgerecht"},
               {id:"breakfast", name:"ontbijt"},
               {id:"desert", name:"nagerecht"}, 
               {id:"main-dish", name:"hoofdgerecht"},
               {id:"pasta", name:"pasta"},
               {id:"pastry", name:"gebak"},
               {id:"risotto", name:"risotto"},
               {id:"salad", name:"salade"}, 
               {id:"soup", name:"soep"}, 
               {id:"vegetarian", name:"vegetarisch"}, 
               {id:"oriental", name:"oosters"}, 
               {id:"italian", name:"italiaans"}, 
               {id:"french", name:"frans"}, 
               {id:"greek", name:"grieks"},
               {id:"fasting", name:"vasten"}, 
               {id:"low-calorie", name:"weinig-calorieën"}, 
               {id:"high-calorie", name:"veel-calorieën"}, 
               {id:"low-fat", name:"weinig-vet"}, 
               {id:"high-fat", name:"veel-vet"}, 
               {id:"low-protein", name:"weinig-proteïne"}, 
               {id:"high-protein", name:"veel-proteïne"}, 
               {id:"low-carb", name:"weinig-koolhydraten"}, 
               {id:"high-carb", name:"veel-koolhydraten"},
               ],
         countSavedTags: 0,
         email: "",
         uid: null,
         displayName: null,
         uidValid: null,
         displayNameValid: null,
      }
   },
   watch: {
      countSavedTags: function(value) {
         if( value == this.tags.length) {
            console.log(value + " tags saved!")
         }
      }
   },
   methods: {
      switchUser: function(){
         this.uidValid = Boolean(this.uid && this.uid.length==28)
         this.displayNameValid = Boolean(this.displayName && this.displayName.length> 0)
         if(this.uidValid && this.displayNameValid) {
            this.$root.$emit("switchUser", {uid:this.uid, displayName:this.displayName})
         }
      },
      addAdminRole: function() {
         const addAdminRole = firebase.functions().httpsCallable('addAdminRole')
         addAdminRole({email: this.email}).then(result => {
            this.showToast(result.data.message, "Info", "success")
         }).catch(error => {
            this.showToast(error.message, "Info", "danger")
         })
      },
      showToast: function(message, title, variant = "default") { 
         this.$emit("showToast", 
            [message, 
            {  
               title: title,
               toaster: 'b-toaster-top-right',
               variant: variant,
               solid: true,
               appendToast: false
            }])
      },
      recipesSaveToGlobal:function() {
         var ref = db.collection("users").doc(this.user.uid);
         ref.collection("recipes").get().then((rcpCol) => {
            rcpCol.forEach( rcpDoc => { 
               const recipe = rcpDoc.data()
               delete recipe.id
               console.log(recipe.naam)
               this.cleanIngredients(recipe)
               db.collection('recipes').add(recipe)
               .then((docRef) => {
                  console.log("Recept aangemaakt: " + docRef.id);
               })
            })
         })
      },
      saveTags:function() {
         this.countSavedTags = 0
         for (let i = 0; i < this.tags.length; i++) {
            const tag = this.tags[i];
            db.collection('tags').doc(tag.id).set(tag).then(() => {
               this.countSavedTags++;
            }).catch(error => {
               console.log(error)
            })  
         }

      },
      cleanRecipes:function(user) {
         var ref = db.collection("users").doc(user.uid);
         ref.collection("recipes").get().then((rcpCol) => {
            rcpCol.forEach( rcpDoc => { 
               let recipe = rcpDoc.data()
               console.log(recipe.naam)
               recipe = this.cleanIngredients(recipe)
               ref.collection('recipes').doc(rcpDoc.id).set(recipe).then(() => {
                  console.log(recipe.naam + " cleaned.")
               }).catch(error => {
                  console.log(error)
               })  
            })
         })
      },
      cleanIngredients:function(recipe) {
         for (let i = 0; i < recipe.ingredients.length; i++) {
            let ingredient = recipe.ingredients[i];
            if('selected' in ingredient){
               delete ingredient.selected
            }
         }
         return recipe
      }
   }
}
</script>
