<template>
   <b-container 
      class="border-0 mt-3 mt-md-5"  >
      
      <b-row class="justify-content-md-center " v-if="shoppingList.length > 0" >
         <b-col class="col-lg-8 mt-md-0 px-0">
            <b-row class="border-0 mt-5 ml-0 mr-0">
               <b-col >
                  <h3>Boodschappenlijst {{activeShoppingListName}}</h3>
               </b-col>
               <b-col cols="auto" class="ml-auto px-0 mr-3 mt-2 mb-2" >
                  <a @click="updateListChecked" href="#">{{hideOnSelect ? "Toon alles" : "Verberg aangevinkt" }} <b-icon :icon="hideOnSelect ? 'caret-right-fill' : 'caret-down-fill'" variant="primary" /></a>
               </b-col>
            </b-row>
            <b-row class="mt-3 ml-0"><b-col>
               <b-link v-b-modal.modalAddShoppingItem href="#" >Voeg boodschappen toe aan lijst</b-link></b-col><b-col>
               <b-link v-b-modal.modal-clear-list href="#" >Maak lijst leeg</b-link></b-col></b-row>
            <b-list-group class="border-0 mt-3" >
      <p class="ml-3 mt-3" v-if="shoppingListWithCategories.length == 0 && shoppingList.length > 0">Alle boodschappen zijn aangevinkt.</p>
      <b-list-group-item v-for="item in shoppingListWithCategories"
         class="d-flex fade-in align-items-left border-0" :key="item.key">
         <b-container 
      >
         <b-row v-if="item.ingredient"
            class="justify-content-md-center" >
            <b-col >
            <b-form-checkbox v-model="item.selected"
                        @change="selectShoppingItem(item)"
                        class="text-dark "
                        size="lg">
            {{getIngredientText(item)}}
               </b-form-checkbox></b-col></b-row>
         <b-row v-if="!item.ingredient" 
            class="justify-content-md-center" >
            <b-col class="pl-0 col-12 ">
            <b>{{getIngredientText(item)}}</b>
               </b-col></b-row></b-container></b-list-group-item></b-list-group></b-col>
         <b-col class="col-12 col-lg-4  mt-3 mt-md-5 mb-3">
            
            <CardSelectedRecipes 
               title="Menu"
               toTitle="Voeg recepten toe aan lijst"
               toLink="/recipe-list"
               :selectedRecipes="shoppingListRecipes" 
               deleteRecipeActive="true"
               @removeRecipe="removeRecipe" /> 
         </b-col></b-row>

      <!-- <modal-select-recipe :userID="userID"  /> -->
      <modal-add-shopping-item id="modalAddShoppingItem" :userID="userID" />

      <b-row v-if="shoppingList.length == 0" class="justify-content-center">
         <b-col class="mt-5">
         <p>Je hebt geen boodschappen. Voeg eerst <b-link to="/recipe-list" >recepten</b-link> toe.</p>
         </b-col>
      </b-row>


    <b-modal title="Boodschappenlijst leeg maken" hide-backdrop
        id="modal-clear-list" size="md"  
        @ok="clearList()">
        <p class="my-1">Weet je zeker dat je de boodschappenlijst met alle items wil leegmaken?</p>
        <template #modal-footer="{ ok, cancel }">
        <b-button variant="secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button variant="danger" @click="ok()">
          OK
        </b-button>
        </template>
    </b-modal>

   </b-container>
</template>
<script>

import db from "../db.js"
// import ModalSelectRecipe from "@/components/ModalSelectRecipe.vue"
import {shoppingListMixin} from "../mixins/shoppingListMixin.js"
import CardSelectedRecipes from "../components/CardSelectedRecipes"
import ModalAddShoppingItem from "../components/ModalAddShoppingItem.vue"
export default {
   name: "shopping-list",
   components: {CardSelectedRecipes, ModalAddShoppingItem},
   data: function() {
      return {
         shoppingList:[],
         shoppingListWithCategories:[],
         shoppingCategories: {},
         shoppingListRecipes: [],
         unsubscribe: null,
         unsubscribeUsrSs: null,
         hideOnSelect: true,
      }
   },
   props: ["user", "userID", "activeUserID", "activeShoppingListName"],
   mixins: [shoppingListMixin],
   watch: {
      activeUserID: function(newID, oldID) {
         if(newID != null && newID != oldID) {
         this.loadData()
         }
      }
   },
   methods: {
      getIngredientText: function(item) {
         if(!item.ingredient) {
            return item.naam.toUpperCase()
         }
         if(item.aantal < 3) {
            item.aantal = this.round(item.aantal, 1)
         } else {
            item.aantal = this.round(item.aantal, 0)
         }
         
         var eenheid = item.aantal > 1 && item.eenheid.meervoud != "" ? item.eenheid.meervoud : item.eenheid.naam;
         var naam = item.aantal > 1 && item.ingredient.meervoud != "" ? item.ingredient.meervoud : item.ingredient.naam;
         
         var txt = ""
         //check if eenheid geen 'stuk' is anders weglaten, 
         //later anders doen namelijk: gewoon weglaten in recept
         if(item.eenheid.id != "tMoR9K8P7BdmyvrbjWfP" ) {
            txt = naam + " (" + item.aantal + " " + eenheid + ")";
         } else {
            txt = naam + " (" + item.aantal + ")";
         }
         return txt;
      },
      selectShoppingItem: function(item) {
         //set selected value over to item in shoppinglist
         for (let i = 0; i < this.shoppingList.length; i++) {
            const itm = this.shoppingList[i];
            if(itm.id == item.id){
               itm.selected = item.selected
               break
            }
         }
         this.saveShoppingList()
      },
      round: function(value, precision) {
         var multiplier = Math.pow(10, precision || 0);
         return Math.round(value * multiplier) / multiplier;
      },
      /**
       * 1)saves the local shoppinglist to first shoppinglist in collection (for now, better would be more lists)
       */
      saveShoppingList: function() {
         let self = this
         let ref = db.collection("users").doc(this.activeUserID)
            .collection('shoppingLists').doc("active")
   
         ref.update({items:self.shoppingList})
         .then(() => {
            console.log("saveShoppingList() success!");
         })
         .catch((error) => {
            console.error("Error saveShoppingList(): ", error);
         });

      },
      addShoppingItem(item){
         if(this.hideOnSelect && item.selected) {
            return
         }
         if(!this.shoppingCategories[item.ingredient.categorie]) {
            this.shoppingCategories[item.ingredient.categorie] = [item]
         } else {
            var catArr = this.shoppingCategories[item.ingredient.categorie]
            for (let i = 0; i < catArr.length; i++) {
               const itm = catArr[i];
               if((item.ingredient.id && item.ingredient.id == itm.ingredient.id && item.eenheid.id == itm.eenheid.id) 
                  || (item.ingredient.naam && item.ingredient.naam == itm.ingredient.naam && item.eenheid.id == itm.eenheid.id)) {
                  itm.aantal += item.aantal
                  return;
               }
            } 
            catArr.push(item)
         }
      },
      createShoppingListWithCategories: function() {
         this.shoppingListWithCategories = []
         var categories = []
         for (const key in this.shoppingCategories) {
            categories.push({key: "key_"+ key, naam:key})
         }
         categories = categories.sort((a,b) => {
            if(!(a.naam && b.naam)) return 1;
            if(a.naam.toLowerCase() < b.naam.toLowerCase()){
               return -1;
            } else {
               return 1;
            }
         })

         for (let i = 0; i < categories.length; i++) {
            var key = categories[i].naam;
            this.shoppingListWithCategories.push(categories[i])
            for (let j = 0; j < this.shoppingCategories[key].length; j++) {
               const itm = this.shoppingCategories[key][j];
               this.shoppingListWithCategories.push(itm)
            }
         }

            
      },
      updateListChecked: function() {
         this.hideOnSelect = !this.hideOnSelect
         this.updateList();
      },
      updateList: function() {
         this.shoppingCategories = {}
         this.shoppingListWithCategories = []
         for (let i = 0; i < this.shoppingList.length; i++) {
            const item = this.shoppingList[i];
            //item.id is used to find item back in shoppingList when selected in shoppingListWithCategories
            item.id = i;
            this.addShoppingItem(item)
         }
         this.createShoppingListWithCategories()
      },
      loadData: function() {
         if(!this.activeUserID) return;
         const ref = db.collection('users').doc(this.activeUserID)
         this.shoppingList = []
         this.shoppingListWithCategories = []
         this.shoppingCategories = {}
         this.unsubscribe = ref.collection('shoppingLists').doc("active")
            .onSnapshot((snapshot) => {
               this.shoppingList = snapshot.data().items ? snapshot.data().items : [] 
               this.shoppingListRecipes = snapshot.data().recipes ? snapshot.data().recipes : []               
               this.updateList()
            })
      },
      clearList() {
         const ref = db.collection('users').doc(this.activeUserID)
         ref.collection('shoppingLists').doc("active").set({}).then(() => {
            console.log("lijst leeg")
         })
         .catch(error => {
            console.log(error);
         })

      }
   },
   mounted: function() {
      if(this.activeUserID === null) {
         this.$router.push("/login");
      }
      this.loadData()
   },
   unmounted: function() {
      this.unsubscribe()
      this.unsubscribeUsrSs()
   }
}
</script>

