<template>
   <!-- modal window for ingredients, v-model="modalIngredientShow"  -->
    <b-modal id="modalRecipeIngredient" modal-class="modal-fullscreen" scrollable content-class="shadow" 
      :title="getIngredientText(selectedIngredient)"
      @show="initComponent"
      @hidden="resetIngredientModal"
      @ok="handleIngredientModalOk">
      <b-form ref="ingredientForm" >
        <b-form-group label="Zoek ingredient" invalid-feedback="Geef een ingredient op." :state="ingredientState">
          <b-row><b-col class="col-8">
            <Autocomplete class="col-12 mt-sm-0 ml-0" :selItem="selectedIngredient" :items="ingredients" textField="text" 
            valueField="value"  selectSize="4" @itemSelected="ingredientSelected" />
          </b-col>
          <b-col class="col-4">
            <b-link v-b-modal.modalAddIngredient class="ml-0">nieuw</b-link>
            <b-link v-b-modal.modalEditIngredient class="ml-2">edit</b-link>
          </b-col></b-row>
        </b-form-group>
        <b-form-group label="aantal" invalid-feedback="Geef een correct aantal op." :state="aantalState">
          <b-form-input ref="inputAantal" class="col-6 px-1 px-sm-2" type="number" required :state="aantalState"
            v-model="selectedIngredient.calculatedNumber" :formatter="formatter"  @input="calcMacroNutrienten()" />
        </b-form-group>
        <b-form-group label="eenheid" invalid-feedback="Geef een eenheid op." :state="eenheidState">
          <b-form-select class="col-6 mt-sm-0" required :state="eenheidState"
            v-model="selectedIngredient.eenheid" :options="units"  @input="calcMacroNutrienten()"  >
              <b-form-select-option :value="{}" disabled>-- Selecteer optie --</b-form-select-option>
            </b-form-select>
        </b-form-group>
        <b-form-group label-size="md" class="mt-2" label="Aantal gram" v-if="selectedIngredient.eenheid.naam != 'gram'" 
          label-for="inputAantal" invalid-feedback="Geef een correct aantal op." >
          <b-form-input id="inputAantal" class="col-6 px-1 px-sm-2" type="number"  
            v-model="selectedIngredient.aantalGr" :formatter="formatter"  @input="calcMacroNutrienten()" />
        </b-form-group>
        <b-form-group label="bewerking" >
          <b-form-select class="col-6 mt-sm-0" 
            v-model="selectedIngredient.bewerking" 
            :options="operations" >
              <b-form-select-option :value="undefined" disabled>-- Selecteer optie --</b-form-select-option>
            </b-form-select> <b-link v-b-modal.modalAddOperation class="ml-2">nieuw</b-link>
            <b-link v-if="isLocalOperation(selectedIngredient.bewerking)" v-b-modal.modalEditOperation class="ml-2">edit</b-link>
        </b-form-group>
        <b-form-group :state="ingredientState">
          <SelectNevoIngredient :nevoIngredients="nevoIngredients" :ingredient="selectedIngredient"
            @selectNevoIngredient="selectNevoIngredient"/>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-container>
          <b-row >
            <b-col cols="auto" class="px-0">
              <button class="btn btn btn-primary" title="Ingredient verwijderen"
                  v-if="selectedIngredient.aantal!==null"
                  @click="trashIngredient(selectedIngredient)" >
                <b-icon icon="trash" style="color: white;"/>
              </button>
            </b-col>
            <b-col cols="auto" class="ml-auto px-0">
                  <b-button size="md" variant="primary" @click="cancel()">
                    Cancel
                  </b-button>
                  <b-button size="md" class="ml-2" variant="primary" @click="ok()">
                    OK
                  </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
      <modal-add-ingredient @ingredientCreated="ingredientCreated" :userID="userID" :ingredients="ingredients" /> 
      <modal-edit-ingredient @ingredientUpdated="ingredientUpdated" :selectedIngredient="selectedIngredient.ingredient" :userID="userID" :ingredients="ingredients" />
      <modal-add-operation @operationCreated="operationCreated" :userID="userID" :operations="operations" />
      <modal-edit-operation @operationUpdated="operationUpdated" :selectedOperation="selectedIngredient.bewerking" :userID="userID" :operations="operations" />
    </b-modal>
</template>

<script>
import ModalEditIngredient from "@/components/ModalEditIngredient";
import modalEditOperation from "@/components/ModalEditOperation";
import ModalAddIngredient from "@/components/ModalAddIngredient";
import ModalAddOperation from "@/components/ModalAddOperation";
import Autocomplete   from "@/components/Autocomplete"
import SelectNevoIngredient   from "./SelectNevoIngredient"
export default {
  name: "modal-recipe-ingredient",
  props: ["recipe", "userID", "recipeID", "units", "ingredients", "operations", "selectedIngredient", "nevoIngredients", "eaters"],
  components: {ModalEditIngredient, modalEditOperation, ModalAddIngredient, ModalAddOperation, Autocomplete, SelectNevoIngredient},
  data: function() {
    return {
      aantalState: null,
      eenheidState: null,
      ingredientState: null,
      ingredient: null,
    }
  },
  methods: {
    selectNevoIngredient(nevoIngr){
      console.log("selectNevoIngredient: ", nevoIngr, this.selectedIngredient )
      var ingr = this.selectedIngredient;
      if(nevoIngr.synoniem && nevoIngr.synoniem != undefined && nevoIngr.synoniem.indexOf("NULL") != 0 && nevoIngr.synoniem.length == 4 ){
        ingr.ingredient.nevo_naam = nevoIngr.synoniem
      } else {
        ingr.ingredient.nevo_naam = nevoIngr.naam
      }
      ingr.ingredient.nevoId = nevoIngr.id
      ingr.ingredient.nevo_code = nevoIngr.nevo_code
      ingr.ingredient.enercc_kCal = nevoIngr.enercc_kcal
      ingr.ingredient.hoeveelheid = nevoIngr.hoeveelheid
      ingr.ingredient.prot_g = nevoIngr.prot_g
      ingr.ingredient.protan_g = nevoIngr.protan_g
      ingr.ingredient.protpl_g = nevoIngr.protpl_g
      ingr.ingredient.fat_g = nevoIngr.fat_g
      ingr.ingredient.fasat_g = nevoIngr.fasat_g
      ingr.ingredient.fasatxr_g = nevoIngr.fasatxr_g
      ingr.ingredient.facid_g = nevoIngr.facid_g
      ingr.ingredient.famscis_g = nevoIngr.famscis_g
      ingr.ingredient.fapu_g = nevoIngr.fapu_g
      ingr.ingredient.fatrs_g = nevoIngr.fatrs_g
      ingr.ingredient.fapun3_g = nevoIngr.fapun3_g
      ingr.ingredient.fapun6_g = nevoIngr.fapun6_g
      ingr.ingredient.cho_g = nevoIngr.cho_g
      ingr.ingredient.starch_g = nevoIngr.starch_g
      ingr.ingredient.chorl_mg = nevoIngr.chorl_mg

      this.calcMacroNutrienten();    
    },
    calcMacroNutrienten() {
      var ingr = this.selectedIngredient;
      if (ingr.eenheid.naam == "gram" ) {
        ingr.aantalGr = Number(ingr.calculatedNumber)
      }
      if (ingr.aantalGr > 0 && ingr.ingredient.enercc_kCal > 0) {
        ingr.kCal_pp = Math.round((ingr.aantalGr/100 * ingr.ingredient.enercc_kCal)/this.eaters)
        
      } else {
        ingr.kCal_pp = 0
      }
    },
    formatter(value) {
      return value < 0 ? 0 : value
    },
    focusInput: function() {  
      setTimeout(() => {
          this.$refs.inputAantal.focus()
      },500)
    },
    initComponent: function() {
        this.focusInput()
    },
    resetIngredientModal: function() {
      this.aantalState = null
      this.eenheidState = null
      this.ingredientState = null
    },
    isLocalOperation: function(selecOprtn) {
      if(selecOprtn == null) return;
      for (let i = 0; i < this.operations.length; i++) {
        const oprtn = this.operations[i];
        if(oprtn.text == selecOprtn.naam){
          return oprtn.local
        }
      }  
    },
    getIngredientText: function(selIngr) {
      var eenheid = selIngr.calculatedNumber > 1 && selIngr.eenheid.meervoud != "" ? selIngr.eenheid.meervoud : selIngr.eenheid.naam;
      var naam = selIngr.calculatedNumber > 1 && selIngr.ingredient.meervoud != "" ? selIngr.ingredient.meervoud : selIngr.ingredient.naam;
      var txt = ""
      if(selIngr.calculatedNumber) {
        txt += selIngr.calculatedNumber + " "
      }
      if(eenheid && selIngr.eenheid.naam != "stuk") {
        txt += eenheid + " "
      }
      if(selIngr) {
        txt += naam + " "
      }
      if(selIngr.bewerking && selIngr.bewerking.naam) {
        txt += selIngr.bewerking.naam + " "
      }
      
      txt = txt.trim()
      return txt 
    },
    handleIngredientModalOk: function(bvModalEvent) {
      console.log("handleIngredientModalOk")
      bvModalEvent.preventDefault();
      this.ingredientModalSubmit();
    },
    ingredientModalSubmit: function() {
      console.log("ingredientModalSubmit")
      this.$refs.ingredientForm.checkValidity()
      this.aantalState = this.selectedIngredient.calculatedNumber > 0
      this.eenheidState = this.selectedIngredient.eenheid.naam != null
      this.ingredientState = this.selectedIngredient.ingredient.naam != null
      if(!(this.aantalState && this.eenheidState && this.ingredientState)) {
        return
      }
      var create = this.selectedIngredient.id == undefined
      this.submitIngredient(this.selectedIngredient, create)
      this.$nextTick(() => {
        this.$bvModal.hide('modalRecipeIngredient')
      })
    }, 
    submitIngredient: function(ingredient, create=false) {
      if(!this.recipe.eaterPersonRatio) {
        this.recipe.eaterPersonRatio = 1;
      }

      ingredient.aantal = Number(this.selectedIngredient.calculatedNumber)/this.recipe.eaterPersonRatio;
      ingredient.aantalGr = Number(this.selectedIngredient.aantalGr)
      if(create) {
        this.$emit("createIngredient", ingredient)
      } else {
        this.$emit("updateIngredient", ingredient)
      }

    },
    trashIngredient: function(ingredient) {
      this.$emit("deleteIngredient", ingredient)
      this.$nextTick(() => {
        this.$bvModal.hide('modalRecipeIngredient')
      })
    },
    ingredientSelected: function(event) {
      this.selectedIngredient.ingredient = event
    },
    ingredientCreated: function(event) {
      this.selectedIngredient.ingredient = event
    },
    ingredientUpdated: function(event) {
      this.selectedIngredient.ingredient = event
    },
    operationUpdated: function(event) {
      this.selectedIngredient.bewerking = event
    },
    operationCreated: function(event) {
      this.selectedIngredient.bewerking = event
    },


   }
}
</script>
