<template>
  <div >
    <form class="py-3 mt-5" @submit.prevent="login" >
      <div class="container mt-5">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="card bg-light">
              <div class="card-body">
                <h3 class="font-weight-light mb-3">Log in</h3>
                <section class="form-group">
                  <div class="col-12 alert alert-danger px-3" v-if="error">
                    {{error}}
                  </div>
                  <label
                    class="form-control-label sr-only"
                    for="Email"
                  >Email</label>
                  <input
                    required
                    class="form-control"
                    type="email"
                    id="email"
                    placeholder="Email"
                    v-model="email"
                  />
                </section>
                <section class="form-group">
                  <input
                    required
                    class="form-control"
                    type="password"
                    placeholder="Password"
                    v-model="password"
                  />
                </section>
                <div class="form-group text-right mb-0">
                  <button
                    class="btn btn-primary"
                    type="submit"
                  >Log in</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <p class="text-center mt-2">
      or
      <router-link to="/register">register</router-link>
      or
      <router-link to="/password-forget">forgot password</router-link>
    </p>
  </div>
</template>
<script>
import firebase from "firebase";
import db from "../db.js";
export default{
  name:"login",
  data:function() {
    return {
      email: "",
      password: "",
      error:""
    }
  },
  methods: {
    login: function() {
      const info = {
        email: this.email,
        password: this.password
      }
      let self = this
      firebase.auth().signInWithEmailAndPassword(info.email, info.password)
        .then((userCredentials)=> {
            
            var user = userCredentials.user
            user.getIdTokenResult(false).then(token => {
              if(user != null && (user.emailVerified || token.claims.admin )) {
                self.$emit("showAdminUI", Boolean(token.claims.admin) )
                var userRef = db.collection('users').doc(user.uid);
                userRef.get().then (doc => {
                  if(!doc.exists) {
                    userRef.set({sharedName: user.displayName}).then (() => {
                      userRef.collection('shoppingLists').doc('active').set({ items:[], recipes: []}).then(() => {
                        console.log("recipelist created")
                        self.$emit("initUser", user)
                      }).catch (error => {
                        console.log("Error creating shopping list", error)
                      })
                    }).catch (error => {
                      console.log("Error creating user: ", error);
                    })
                  } else {
                      self.$emit("initUser", user)
                  }
                }).catch (error => {
                  console.log(error)
                })
              } else {
                self.error = "E-mail is niet gevalideert"
              }

            }).catch(error => {
              console.log(error)
            }) 
          }, error => {
            self.error = error.message;
          }
        )
    }

  }
}
</script>

