<template>
   <b-modal id="modalInviteUser" modal-class="modal-fullscreen" title="Nodig iemand uit" hide-backdropt size="md"
      @ok="handleSubmitOK">
      <b-form ref="form" @submit.stop.prevent="modalHandleSubmitUser()">
         <b-form-group label="e-mail: " :invalid-feedback="emailInvalidInfo" :state="emailState">
         <b-form-input class="col-12 px-1 px-sm-2" type="email" required :state= "emailState" 
            v-model="email" />
         </b-form-group>
      </b-form>
   </b-modal>
</template>

<script>
import firebase from "firebase"
import "firebase/functions"

export default {
   name: "modal-invite-user",
   props: ['userID', 'displayName'],
   data: function() {
      return {
         email: "",
         emailState: null,
         emailInvalidInfo: null
      }
   },
   methods: {
      handleSubmitOK: function(bvModalEvent){
         bvModalEvent.preventDefault();
         this.modalHandleSubmitUser()
      },
      modalHandleSubmitUser: function() {
         this.$refs.form.checkValidity()
         this.emailState = this.email != ''
         if(!this.emailState ) {
            return
         }
         const inviteUser = firebase.functions().httpsCallable(
            "inviteUser?email=" + this.email + "&inviterUid=" + this.userID + "&displayName=" + this.displayName
         )
         inviteUser().then((response) => {
            if(response.data.result) {
               let event = ["Er is een uitnodiging zichtbaar bij het inloggen voor de gebruiker met e-mail '" + this.email + "'", {
                  title: `Uitnodiging`,
                  toaster: 'b-toaster-top-center',
                  variant: 'warning',
                  solid: true,
                  appendToast: false
               }]
               this.$emit("showToast", event)
               this.$nextTick(() => {
                  this.$bvModal.hide('modalInviteUser')
               })
            } else {
               this.emailState = false
               this.emailInvalidInfo = response.data.info
            }
         })
         .catch(error => {
            console.log(error)
            this.emailInvalidInfo = error
         })
      },
   },
   mounted: function() {
      this.$root.$on('bv::modal::show', () => {
         this.email = ""
         this.emailState = null;
         this.emailInvalidInfo = null;
      })
      
   }
}
</script>

<style>
</style>

